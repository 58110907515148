<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading && !isLoadingRequestTypes" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'View' : 'Create'}} Scheduled Ticket</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="row sdesk-grey-row">
      <div class="col-sm-2 formHeader alignMiddle p-10">
        Request Type <span class="text-danger">*</span>
      </div>
      <div class="col-sm p-10">
          <ng-select [items]="requestTypes"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.RequestTypeId"
                     [clearable]="false"
                     (ngModelChange)="refreshOptions(true)">
          </ng-select>
      </div>
    </div>

    <div *ngIf="this.loadedData.RequestTypeId && !this.isLoadingOptions">

      <div class="row sdesk-grey-row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Frequency <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="frequencyOptions"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Frequency"
                     [clearable]="false"
                     (ngModelChange)="selectWeekDays()">
          </ng-select>
        </div>
      </div>

      <div class="row sdesk-grey-row" *ngIf="loadedData.Frequency ===3">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Every <span class="text-danger">*</span>
        </div>
        <div class="col-sm-2 p-10">
        <input type="number"  [(ngModel)]="loadedData.MonthFrequency"
                            class="form-control" style="width: 100px;" autocomplete="off" step="1" min="1" >
        </div>

        <div class="col-sm-2 formHeader alignMiddle p-10">
          Month
        </div>
      </div>

      <div class="row sdesk-grey-row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Start On <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
        <!-- <input type="datetime-local"  [(ngModel)]="loadedData.StartDateTime"
                            class="form-control" style="width: 200px;" autocomplete="off" > -->
                            <p-calendar  
                            [(ngModel)]="loadedData.StartDateTime"
                            [showIcon]="true" 
                            ariaLabel="Date"
                            [showTime]="true" 
                            [iconDisplay]="'input'" 
                            [hourFormat]="12" 
                            inputId="Start_Date"
                            [readonlyInput]="true"
                            [showButtonBar]="true"
                            [hideOnDateTimeSelect]="false"
                            [locale]="{firstDayOfWeek: 1, today: 'Today', clear: 'Clear'}"                            
                          ></p-calendar>                
        </div>
      </div>

      <div class="row sdesk-grey-row" *ngIf="loadedData.WeekdaysToRun != null && loadedData.Frequency ===1 " >
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Week Days To Run <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
      <app-sdesk-multi-select
            [data]="daysOfTheWeek"
            [(selectedData)]="loadedData.WeekdaysToRun">
        </app-sdesk-multi-select>
        </div>
        </div>


      <div class="row sdesk-grey-row">
        <div class="col-sm-2 formHeader p-10">
          Requester <span class="text-danger">*</span>

        </div>
        <div class="col-sm p-10">
          <i class="fas fa-user icon-size-medium m-r-10"></i>
          {{this.getValueName(this.loadedData.Request_RequesterId, this.options.requester)}}
        </div>
        <div *ngIf="!loadedId" class="col-sm-1">
          <div (click)="openUserSearchModal()" class="sdesk-right-button divLink vertical-center-one_row_search">
            <i class="fas fa-search icon-size-medium"></i>
          </div>
        </div>
      </div>

      <div class="row sdesk-grey-row">
        <div class="row" *ngIf="options.RequestTypeOptions.CustomerEnabled">
          <div class="col-sm-2 formHeader p-10">
            Customer
          </div>
          <div class="col-sm p-10">
            <i class="fas fa-building icon-size-medium m-r-10"></i>
            {{this.getValueName(this.loadedData.Request_CustomerId, this.options.customer)}}
          </div>
          <div class="col-sm-1">
            <div *ngIf="!loadedId" (click)="openCustomerServiceSearchModal()" class="sdesk-right-button divLink vertical-center" style="border-radius: 0px;">
            </div>
          </div>
        </div>

        <div class="row" *ngIf="options.RequestTypeOptions.CustomerEnabled">
          <div class="col-sm-2 formHeader p-10">
            Location
          </div>
          <div class="col-sm p-10">
            <i class="fas fa-map-marker-alt icon-size-medium m-r-10"></i>
            {{this.getValueName(this.loadedData.Request_CustomerLocationId, this.options.customerLocations)}}

          </div>
          <div class="col-sm-1">
            <div *ngIf="!loadedId" (click)="openCustomerServiceSearchModal()" class="sdesk-right-button divLink vertical-center" style="border-radius: 0px;">
              <i class="fas fa-search icon-size-medium"></i>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="options.RequestTypeOptions.ServiceEnabled">
          <div class="col-sm-2 formHeader p-10">
            Service
          </div>
          <div class="col-sm p-10">
            <i class="fas fa-cog icon-size-medium m-r-10"></i>
            {{this.getValueName(this.loadedData.Request_ServiceId, this.options.service)}}
          </div>
          <div class="col-sm-1">
            <div *ngIf="!loadedId" (click)="openCustomerServiceSearchModal()" class="sdesk-right-button divLink vertical-center" style="border-radius: 0px;">
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Subject <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.Request_Subject" type="text" class="form-control" id="Subject" placeholder="Enter Subject" autocomplete="off">

        </div>
      </div>

      <div class="row">
        <div class="col-sm-2 formHeader p-10">
          Description <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <textarea rows="5" id="RequestBody" [(ngModel)]="loadedData.Request_Body" class="form-control" style="width: 100%;" spellcheck="true" autocomplete="off"></textarea>
        </div>
      </div>

      <div class="row sdesk-grey-row" *ngIf="options.RequestTypeOptions.ImpactEnabled">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Impact
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="options.impact"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Request_ImpactId"
                     [clearable]="false">
          </ng-select>
        </div>
      </div>

      <div class="row sdesk-grey-row" *ngIf="options.RequestTypeOptions.UrgencyEnabled">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Urgency
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="options.urgency"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Request_UrgencyId"
                     [clearable]="false">
          </ng-select>
        </div>
      </div>

      <div class="row sdesk-grey-row" *ngIf="options.RequestTypeOptions.PriorityEnabled && !options.RequestTypeOptions.AutoPriority">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Priority
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="options.priority"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Request_PriorityId"
                     [clearable]="false">
          </ng-select>
        </div>
      </div>

      <div class="row sdesk-grey-row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Group
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="options.group"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Request_GroupId"
                     [clearable]="false"
                     (ngModelChange)="refreshOptions(true)">
          </ng-select>
        </div>
      </div>

      <div class="row sdesk-grey-row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Agent
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="options.agent"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Request_AgentId"
                     [clearable]="true"
                     [disabled]="!loadedData.Request_GroupId">
          </ng-select>
        </div>
      </div>

      <div class="row sdesk-grey-row">
        <div class="col-sm-2 formHeader alignMiddle p-10">
          Task List
        </div>
        <div class="col-sm p-10">
          <ng-select [items]="taskLists"
                     bindLabel="Name"
                     bindValue="Id"
                     [(ngModel)]="loadedData.Request_TaskListId"
                     [clearable]="true">
          </ng-select>
        </div>
      </div>

<!--      <div *ngFor="let customField of options.CustomFields" class="row sdesk-grey-row">-->
<!--        <div class="col-sm-2 formHeader alignMiddle p-10">-->
<!--          {{customField.Name}}-->
<!--        </div>-->
<!--        <div class="col-sm p-10">-->
<!--          <app-custom-field-dropdown-->
<!--            (optionOutput)="setCustomFieldValue($event)"-->
<!--            [customFieldId]="customField.CustomFieldId"-->
<!--            [customFieldOptions]="customField.Options"></app-custom-field-dropdown>-->
<!--        </div>-->
<!--      </div>-->

    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
    </div>

  </div>
</app-blade-right>
