<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right  *ngIf="!isLoading && !isLoadingRequestTypes" size="blade-large">
  <div class="p-10 p-b-0">
    <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
    <h3>{{this.loadedId ? 'Edit' : 'Create'}} Automation Rule</h3>
  </div>
  <hr>
  <div class="container p-0">

    <div class="col-12 m-b-20 ">
      <ul class="nav dialogTabs">
        <li class="nav-item divLink" (click)="selectedTab = 'main'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'main'">
            Main
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'conditions'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'conditions'">
            Conditions
          </a>
        </li>

        <li class="nav-item divLink" (click)="selectedTab = 'actions'">
          <a class="nav-link-thread" [class.active]="selectedTab == 'actions'">
            Actions
          </a>
        </li>

      </ul>
    </div>

    <!-- Tab Content -->

    <div *ngIf="selectedTab == 'main'">
      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Name <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">

        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Description
        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.Description" type="text" class="form-control" id="Description" placeholder="Description" autocomplete="off">

        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Apply to Request Type <span class="text-danger">*</span>
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="requestTypes"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.RequestTypeId"
                     (ngModelChange)="getOptions()"
                     [clearable]="false"
                     [disabled]="this.loadedId">
          </ng-select>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">

        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.Enabled" class="form-check-input" id="Enabled" type="checkbox"><label for="Enabled" class="p-l-10">Enabled</label>

        </div>
      </div>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">

        </div>
        <div class="col-sm p-10">
          <input [(ngModel)]="loadedData.RunOnce" class="form-check-input" id="RunOnce" type="checkbox"><label for="RunOnce" class="p-l-10">Run Once (Per Ticket)</label>

        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-sm-3 formHeader alignMiddle p-10">
          Trigger When
        </div>
        <div class="col-sm p-10">

          <!-- Cannot change requestType on edit -->
          <ng-select [items]="triggerOptions"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="loadedData.Trigger"
                     [clearable]="false">
          </ng-select>
        </div>
      </div>

      <div *ngIf="loadedData.Trigger == 2">

        <div class="row">
          <div class="col-sm-3 formHeader alignMiddle p-10 p-l-20">
            Which SLA?
          </div>
          <div class="col-sm p-10">

            <ng-select [items]="slaTriggerOptions"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="loadedData.Trigger_Sla_Type"
                       [clearable]="false">
            </ng-select>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-3 formHeader alignMiddle p-10 p-l-20">
            Before / After Breach?
          </div>
          <div class="col-sm p-10">

            <ng-select [items]="slaTriggerTypeOptions"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="loadedData.Trigger_Sla_Trigger_Type"
                       [clearable]="false">
            </ng-select>
          </div>
        </div>

        <div class="row" *ngIf="loadedData.Trigger_Sla_Trigger_Type == 1 || loadedData.Trigger_Sla_Trigger_Type == 3">
          <div class="col-sm-3 formHeader alignMiddle p-10 p-l-20">
            How many minutes {{loadedData.Trigger_Sla_Trigger_Type == 1 ? 'before' : 'after'}}?
          </div>
          <div class="col-sm p-10">
            <input [(ngModel)]="loadedData.Trigger_Sla_Trigger_Minutes" type="number" class="form-control" id="Trigger_Sla_Trigger_Minutes" placeholder="" autocomplete="off">
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="selectedTab == 'conditions' && loadedData.RequestTypeId">
      <!-- Filter Group -->
      <div class="row sdesk-grey-row">
        <div class="col-2 formHeader alignMiddle p-10">
          Group
        </div>
        <div class="col-4 p-10">
          <select [(ngModel)]="loadedData.Group_FilterTypeId" class="form-control" data-style="btn-white">
            <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
          </select>
        </div>
        <div *ngIf="loadedData.Group_FilterTypeId == 1 || loadedData.Group_FilterTypeId == 4" class="col p-10">

          <app-sdesk-multi-select [data]="options.group" [(selectedData)]="loadedData.GroupValues"></app-sdesk-multi-select>

        </div>
      </div>

      <!-- Filter Agent -->
      <div class="row sdesk-grey-row">
        <div class="col-2 formHeader alignMiddle p-10">
          Agent
        </div>
        <div class="col-4 p-10">
          <select [(ngModel)]="loadedData.Agent_FilterTypeId" class="form-control" data-style="btn-white">
            <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
          </select>
        </div>
        <div *ngIf="loadedData.Agent_FilterTypeId == 1 || loadedData.Agent_FilterTypeId == 4" class="col p-10">
          <app-sdesk-multi-select [data]="options.agent" [(selectedData)]="loadedData.AgentValues"></app-sdesk-multi-select>

        </div>
      </div>

      <!-- Filter Status -->
      <div class="row sdesk-grey-row">
        <div class="col-2 formHeader alignMiddle p-10">
          Status
        </div>
        <div class="col-4 p-10">
          <select [(ngModel)]="loadedData.Status_FilterTypeId" class="form-control" data-style="btn-white">
            <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
          </select>
        </div>
        <div *ngIf="loadedData.Status_FilterTypeId == 1 || loadedData.Status_FilterTypeId == 4" class="col p-10">
          <app-sdesk-multi-select [data]="options.status" [(selectedData)]="loadedData.StatusValues"></app-sdesk-multi-select>


        </div>
      </div>

      <!-- Filter Priority -->
      <div *ngIf="options.RequestTypeOptions.PriorityEnabled" class="row sdesk-grey-row">
        <div class="col-2 formHeader alignMiddle p-10">
          Priority
        </div>
        <div class="col-4 p-10">
          <select [(ngModel)]="loadedData.Priority_FilterTypeId" class="form-control" data-style="btn-white">
            <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
          </select>
        </div>
        <div *ngIf="loadedData.Priority_FilterTypeId == 1 || loadedData.Priority_FilterTypeId == 4" class="col p-10">

          <app-sdesk-multi-select [data]="options.priority" [(selectedData)]="loadedData.PriorityValues"></app-sdesk-multi-select>
        </div>
      </div>

      <!-- Filter Customer -->
      <div *ngIf="options.RequestTypeOptions.CustomerEnabled" class="row sdesk-grey-row">
        <div class="col-2 formHeader alignMiddle p-10">
          Customer
        </div>
        <div class="col-4 p-10">
          <select [(ngModel)]="loadedData.Customer_FilterTypeId" class="form-control" data-style="btn-white">
            <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
          </select>
        </div>
        <div *ngIf="loadedData.Customer_FilterTypeId == 1 || loadedData.Customer_FilterTypeId == 4" class="col p-10">
          <app-sdesk-multi-select [data]="options.customer" [(selectedData)]="loadedData.CustomerValues"></app-sdesk-multi-select>
        </div>
      </div>

      <!-- Filter Service -->
      <div *ngIf="options.RequestTypeOptions.ServiceEnabled" class="row sdesk-grey-row">
        <div class="col-2 formHeader alignMiddle p-10">
          Service
        </div>
        <div class="col-4 p-10">
          <select [(ngModel)]="loadedData.Service_FilterTypeId" class="form-control" data-style="btn-white">
            <option *ngFor="let option of this.filterTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
          </select>
        </div>
        <div *ngIf="loadedData.Service_FilterTypeId == 1 || loadedData.Service_FilterTypeId == 4" class="col p-10">
          <app-sdesk-multi-select [data]="options.service" [(selectedData)]="loadedData.ServiceValues"></app-sdesk-multi-select>
        </div>
      </div>

      <!-- Filter Subject -->
      <div class="row sdesk-grey-row">
        <div class="col-2 formHeader alignMiddle p-10">
          Subject
        </div>
        <div class="col-4 p-10">
          <select [(ngModel)]="loadedData.Subject_FilterTextTypeId" class="form-control" data-style="btn-white">
            <option *ngFor="let option of this.filterTextTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
          </select>
        </div>
        <div *ngIf="loadedData.Subject_FilterTextTypeId == 1 || loadedData.Subject_FilterTextTypeId == 2" class="col p-10">
          <app-tags-input [(model)]="loadedData.SubjectValues"></app-tags-input>
        </div>
      </div>

      <!-- Filter Body -->
      <div class="row sdesk-grey-row">
        <div class="col-2 formHeader alignMiddle p-10">
          Body
        </div>
        <div class="col-4 p-10">
          <select [(ngModel)]="loadedData.Body_FilterTextTypeId" class="form-control" data-style="btn-white">
            <option *ngFor="let option of this.filterTextTypes" [label]="option.name" [value]="option.id" selected="selected"></option>
          </select>
        </div>
        <div *ngIf="loadedData.Body_FilterTextTypeId == 1 || loadedData.Body_FilterTextTypeId == 2" class="col p-10">
          <app-tags-input [(model)]="loadedData.BodyValues"></app-tags-input>
        </div>
      </div>
    </div>

    <div *ngIf="selectedTab == 'conditions' && !loadedData.RequestTypeId">
      <h4>Please select a request type on the Main tab before continuing.</h4>
    </div>

    <div *ngIf="selectedTab == 'actions' && loadedData.RequestTypeId">

      <div class="row">

        <div class="col p-10">
          <div>
            <div class="input-group">

              <select [(ngModel)]="selectedActionDropdown" class="form-control input-sm" id="" data-style="btn-white">
                <option *ngFor="let option of getActions()" [value]="option.id" [label]="option.name"></option>
              </select>

              <button [disabled]="!selectedActionDropdown"  (click)="addAction()" class="btn btn-primary m-0">Add</button>
            </div>
          </div>

        </div>
      </div>




      <div *ngFor="let action of loadedData.Actions; let i = index" class="row sdesk-grey-row">
        <div class="col-12 formHeader alignMiddle p-10">
          <i (click)="removeAction(i)" class="fas fa-times p-5 float-end fa-15x divLink"></i>
          <h4 class="p-t-10">{{ getActionName(action.ActionId) }}</h4>
        </div>
        <div class="col-12 p-10">

          <!-- <ng-select *ngIf="action.ActionId == 1" [items]="options.group"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="action.ValueId"
                       [clearable]="true">
          </ng-select> -->

          <div *ngIf="action.ActionId == 2">
            <p>Select Group</p>
            <ng-select [items]="options.group"
                        bindLabel="name"
                        bindValue="id"
                        [(ngModel)]="action.RelatedValueId"
                        [clearable]="true"
                        (ngModelChange)="getOptions(action, action.RelatedValueId)">
              </ng-select>
            <p>Select Agent</p>
            <ng-select [items]="options.agent"
                      bindLabel="name"
                      bindValue="id"
                      [(ngModel)]="action.ValueId"
                      [clearable]="true"
                      [disabled]="!action.RelatedValueId">

            </ng-select>

          </div>


          <ng-select *ngIf="action.ActionId == 3" [items]="options.status"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="action.ValueId"
                     [clearable]="true">
          </ng-select>

          <ng-select *ngIf="action.ActionId == 4" [items]="options.customer"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="action.ValueId"
                     [clearable]="true">
          </ng-select>

          <ng-select *ngIf="action.ActionId == 5" [items]="options.service"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="action.ValueId"
                     [clearable]="true">
          </ng-select>

          <ng-select *ngIf="action.ActionId == 6" [items]="options.impact"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="action.ValueId"
                     [clearable]="true">
          </ng-select>

          <ng-select *ngIf="action.ActionId == 7" [items]="options.urgency"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="action.ValueId"
                     [clearable]="true">
          </ng-select>

          <ng-select *ngIf="action.ActionId == 13" [items]="options.priority"
                     bindLabel="name"
                     bindValue="id"
                     [(ngModel)]="action.ValueId"
                     [clearable]="true">
          </ng-select>

          <div *ngIf="action.ActionId == 10">
            <div class="row">
              <div class="col-sm-3 formHeader alignMiddle p-10">
                Email Address <span class="text-danger">*</span>
              </div>
              <div class="col-sm p-10">
                <input [(ngModel)]="action.EmailAddress" type="text" class="form-control" id="EmailAddress" placeholder="EmailAddress" autocomplete="off">
              </div>
            </div>
          </div>
          <ng-container *ngIf="action.ActionId == 10 || action.ActionId == 17">
            <div class="row">
              <div class="col-sm-3 formHeader alignMiddle p-10">
                Subject<span class="text-danger">*</span>
              </div>
              <div class="col-sm p-10">
                <input [(ngModel)]="action.Subject" type="text" class="form-control" id="Subject" placeholder="Add a subject" autocomplete="off">
              </div>
            </div>
          </ng-container>

          <div *ngIf="action.ActionId == 11 || action.ActionId == 15 || action.ActionId == 16">
            <div class="row">
              <div class="col-sm-3 formHeader alignMiddle p-10">
                Webhook URL <span class="text-danger">*</span>
              </div>
              <div class="col-sm p-10">
                <input [(ngModel)]="action.SlackWebhookUrl" type="text" class="form-control" id="SlackWebhookUrl" placeholder="Webhook Url" autocomplete="off">
              </div>

              <div *ngIf="action.ActionId == 15 || action.ActionId == 16">
                <div class="row">
                  <div class="col-sm-3 formHeader p-10">
                    Select Method <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm p-10">
                    <select [(ngModel)]="action.Method" class="form-control" value="POST" id="Method">
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                      <option value="PUT">PUT</option>
                      <option value="PATCH">PATCH</option>
                      <option value="DELETE">DELETE</option>
                    </select>
                  </div>
                </div>
              </div>

              <div *ngIf="action.ActionId == 15 && (action.Method && action.Method != 'GET')">
                <div class="row">
                  <div class="col-sm-3 formHeader p-10">
                    Webhook Payload <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm p-10">
                    <textarea rows="5" id="TemplateText1" [(ngModel)]="action.TemplateText" class="form-control" style="width: 100%;"></textarea>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <app-template-syntax></app-template-syntax>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="action.ActionId == 16 && (action.Method && action.Method != 'GET')">
                <div class="row">
                  <div class="col-sm-3 formHeader p-10">
                    Webhook Payload <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm p-10">
                    <textarea rows="30" id="TemplateText" [(ngModel)]="action.TemplateText" class="form-control" style="width: 100%;"></textarea>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <app-template-syntax></app-template-syntax>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="action.ActionId == 15">
                <div class="container">
                  <div class="row">
                    <div class="col-sm formHeader p-10">
                      Webhook Headers <span class="text-muted">(optional)</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 formHeader alignMiddle p-10">
                      Accept:
                    </div>
                    <div class="col-sm p-10">
                      <input [(ngModel)]="action.HeaderAccept" type="text" class="form-control" id="HeaderAccept" placeholder="Accept parameters" autocomplete="off">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 formHeader alignMiddle p-10">
                      Content-Type:
                    </div>
                    <div class="col-sm p-10">
                      <input [(ngModel)]="action.HeaderContentType" type="text" class="form-control" id="HeaderContentType" placeholder="Content-Type parameters" autocomplete="off">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 formHeader alignMiddle p-10">
                      Authorization:
                    </div>
                    <div class="col-sm p-10">
                      <input [(ngModel)]="action.HeaderAuthorization" type="text" class="form-control" id="HeaderAuthorization" placeholder="Authorization parameters" autocomplete="off">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 formHeader alignMiddle p-10">
                      User-Agent:
                    </div>
                    <div class="col-sm p-10">
                      <input [(ngModel)]="action.HeaderUserAgent" type="text" class="form-control" id="HeaderUserAgent" placeholder="User-Agent parameters" autocomplete="off">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3 formHeader alignMiddle p-10">
                      Custom-Header:
                    </div>
                    <div class="col-sm p-10">
                      <input [(ngModel)]="action.HeaderCustomHeader" type="text" class="form-control" id="HeaderCustomHeader" placeholder="Custom-Header parameters" autocomplete="off">
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div *ngIf="action.ActionId == 12">
            <div class="row">
              <div class="col-sm-3 formHeader alignMiddle p-10">
                Mobile Number <span class="text-danger">*</span>
              </div>
              <div class="col-sm p-10">
                <input [(ngModel)]="action.MobileNumber" type="text" class="form-control" id="MobileNumber" placeholder="MobileNumber" autocomplete="off">
              </div>
            </div>
          </div>

          <div *ngIf="(action.ActionId >= 8 && action.ActionId <= 12) || action.ActionId == 14 || action.ActionId == 17">
            <div class="row">
              <div class="col-sm-3 formHeader p-10">
                Template Text <span class="text-danger">*</span>
              </div>
              <div class="col-sm p-10">

                <span *ngIf="!showEditor" (click)="showEditor = !showEditor" class="divLink float-end">(Show Editor)</span>
                <span *ngIf="showEditor" (click)="showEditor = !showEditor" class="divLink float-end">(Show HTML)</span>

                <quill-editor *ngIf="showEditor" [(ngModel)]="action.TemplateText" [styles]="{height: '550px'}" placeholder=""></quill-editor>

                <textarea *ngIf="!showEditor" rows="5" id="TemplateText" [(ngModel)]="action.TemplateText" class="form-control" style="width: 100%;"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <app-template-syntax></app-template-syntax>
              </div>
            </div>
          </div>



        </div>

      </div>




      </div>

    <div *ngIf="selectedTab == 'actions' && !loadedData.RequestTypeId">
      <h4>Please select a request type on the Main tab before continuing.</h4>
    </div>

    <div class="m-t-20">
      <div (click)="save()" class="btn btn-primary">Save</div>
      <div (click)="close()" class="btn btn-cancel">Cancel</div>
    </div>

  </div>
</app-blade-right>
