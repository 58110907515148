<div class="row m-t-20">
  <div class="col-12">

    <ul class="nav m-b-5">
      <li class="nav-item divLink" [routerLink]="['/agent/admin/global/settings']" [queryParams]="{ tab: 'globalsettings' }">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Global Settings
        </a>
      </li>

      <li class="nav-item divLink" [routerLink]="['/agent/admin/global/types']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Request Types
        </a>
      </li>

      <li class="nav-item divLink" [routerLink]="['/agent/admin/global/sso']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Single Sign On
        </a>
      </li>

      <li class="nav-item divLink" [routerLink]="['/agent/admin/global/azuread']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Azure User Sync
        </a>
      </li>

      <li class="nav-item divLink" [routerLink]="['/agent/admin/global/links']">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Ticket Linking
        </a>
      </li>
      <li class="nav-item divLink" [routerLink]="['/agent/admin/global/settings']" [queryParams]="{ tab: 'ticketsettings' }">
        <a class="nav-link-thread" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          Ticket Settings
        </a>
      </li>
    </ul>
  </div>
  <div class="col-12 sectionBox">
    <div class="m-t-20">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
