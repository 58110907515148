import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FilterService} from "../../../services/api/filter/filter.service";
import {SelectionModel} from "@angular/cdk/collections";
import {FilterCreateEditBladeComponent} from "../../../shared/filter-create-edit-blade/filter-create-edit-blade.component";
import {DialogService} from "../../../services/dialog-service/dialog.service";
import {timer} from "rxjs";
import { saveAs } from 'file-saver';
import { ScheduleFilterReportsComponent } from 'src/app/shared/schedule-filter-reports/schedule-filter-reports.component';
import moment from 'moment';
import { OptionsApiService } from 'src/app/services/api/options/options-api.service';
import { UserPermissionsService } from 'src/app/services/user-permissions/user-permissions.service';
import { ApiAdminUsersService } from 'src/app/services/api/admin/admin-users-api/admin-users-api.service';
import { SdeskHelper } from 'src/app/shared/helper/SdeskHelper';



@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  public filterId;
  public viewName;
  filterData;
  filterOptions;
  searchText = '';
  public selectedRequestTypeId;
  public isLoading = true;
  public data;

  public OrderById;
  public OrderAsc = 1;
  public CurrentPage;
  public customColumns = [];
  totalCount;

  totalColumnSelected = [];

  isSearchDivVisible: boolean = false;
  first: number = 0;
  rows: number = 50;
  showingRowscount: number = 50;
  fromRowscount: number = 1;
  currentPage: number = 1;
  recordCount;
  public userPermissionsSubscription;
  public accessPermissions;
  public selectedTickets = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  columnOptions = [
    {id: 2, name: 'Status'},
    {id: 4, name: 'Agent'},
    {id: 8, name: 'Created'},
    {id: 9, name: 'Subject'},
    {id: 10, name: 'Requester'},
    {id: 11, name: 'Id'},
    {id: 12, name: 'Responded'},
    {id: 13, name: 'Resolved'},
    {id: 14, name: 'Updated'},
    {id: 20, name: 'Group'},
    {id: 25, name: 'Requester Responded'},
    {id: 26, name: 'Last Status Change'},
    {id: 41, name: 'Requester Email'},
  ];

  defaultTicketOptions = 'all'

  ticketOptions = [
    {'value': 'all', name: 'All Your Tickets'},
    {'value': 'requester', name: 'Tickets Requested By You'},
    {'value': 'agent', name: 'Tickets Assigned To You'},
  ]

  columnOptionsCmdbEnabled = [
    {id: 2, name: 'Status'},
    {id: 8, name: 'Created'},
    {id: 9, name: 'Asset Name'},
    {id: 10, name: 'Owner'},
    {id: 11, name: 'Asset No.'},
    {id: 14, name: 'Updated'},
    {id: 26, name: 'Last Status Change'},
    {id: 30, name: 'Asset Type'},
    {id: 31, name: 'Serial Number'},
    {id: 32, name: 'Imei'},
    {id: 33, name: 'Manufacturer'},
    {id: 34, name: 'Model'},
    {id:35, name:'Cost'}
  ];

  defaultSearchType = 11;

  isLoadingOptions = false;

  refreshTimer = timer(60000, 60000);
  timerSubscription;

  public sort_my_tickets = {orderby: 0, ascending: null};
  public orderTableBy = '';

  constructor(private route: ActivatedRoute,
              private apiFilter: FilterService,
              private dialogService: DialogService,
              private viewReference: ViewContainerRef,
              private optionsApi: OptionsApiService,private userPermissions: UserPermissionsService,
              private apiAdminUsersService: ApiAdminUsersService
  ) {
    this.timerSubscription = this.refreshTimer.subscribe(val => this.loadData());
    userPermissions.refreshUserPermissions();
    userPermissions.userPermissionsSubscription.subscribe(data => {
      this.userPermissionsSubscription = data
      if(this.userPermissionsSubscription)
        {
          this.getAccessDetails(this.userPermissionsSubscription.AgentId);
        }
    });
  }

  private initSortStorage() {
    this.sort_my_tickets = JSON.parse(localStorage.getItem('sort_my_tickets'));
    // if not existing on local storage, create it
    if (this.sort_my_tickets === null) {
      let sort_obj = {orderby: 0, ascending: null};
      localStorage.setItem('sort_my_tickets', JSON.stringify(sort_obj));
    } else {
      this.orderTableBy = SdeskHelper.getColName(this.sort_my_tickets.orderby, SdeskHelper.TICKETING);
   }

   // this is for the selected ticket option on the dropdown, if thres any
   this.defaultTicketOptions = SdeskHelper.getSelectedTicketOption();
  }

  ngOnInit() {
    this.initSortStorage();
    this.route.paramMap.subscribe(params => {
      this.filterId = params.get("FilterId");
      this.viewName = params.get("ViewName");
      this.selectedRequestTypeId = params.get("RequestTypeId");
      this.loadDataOnInit();
    })
  }
  getAccessDetails(agentId){
    this.apiAdminUsersService.get(agentId).then(response => {
      this.accessPermissions = response;

    })
  }

  ngOnDestroy() {
    // Unsubscribe from the timer
    this.customColumns = []
    this.timerSubscription.unsubscribe();
  }

  clearSelection() {
    this.selectedTickets.clear();
  }

  getDisplayedPages(): number[] {
    const totalPages = this.getPages().length;
    const currentPage = this.currentPageNo();
    const pagesToShow = 20;
    const firstPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
    const lastPage = Math.min(firstPage + pagesToShow - 1, totalPages);

    const displayedPages = [];
    for (let i = firstPage; i <= lastPage; i++) {
      displayedPages.push(i);
    }
    return displayedPages;
  }

  toggleSearchDiv() {
    this.isSearchDivVisible = !this.isSearchDivVisible;
    this.getOptions();
  }



  getSearchType(){
    return this.isCmdbEnabled() ? this.columnOptionsCmdbEnabled : this.columnOptions;
  }

  getOptions(){
    this.isLoadingOptions = true;
    console.log(this.selectedRequestTypeId);
    this.optionsApi.DynamicOptions(this.data.RequestTypeId).then(data => {
      this.filterOptions = data;
      this.isLoadingOptions = false;

      if (this.filterOptions.RequestTypeOptions.ApplySla) {
        this.columnOptions.push({id: 15, name: 'Response Due'});
        this.columnOptions.push({id: 16, name: 'Resolution Due'});
        this.columnOptions.push({id: 29, name: 'Next SLA Due'});
      }

      // If SLA is enabled for this Request Type then add the Sla Indicator column
      if(this.data.create && this.filterOptions.RequestTypeOptions.ApplySla) {
        this.filterData.Columns.push({id: 29, name: 'Next SLA Due'});
      }

      if (this.filterOptions.RequestTypeOptions.CustomerEnabled) {
        this.columnOptions.push({id: 21, name: 'Customer'});
      }
      if (this.filterOptions.RequestTypeOptions.ImpactEnabled) {
        this.columnOptions.push({id: 22, name: 'Impact'});
      }
      if (this.filterOptions.RequestTypeOptions.PriorityEnabled) {
        this.columnOptions.push({id: 3, name: 'Priority'});
      }
      if (this.filterOptions.RequestTypeOptions.ResolutionCodesEnabled) {
        this.columnOptions.push({id: 24, name: 'Resolution Code'});
      }
      if (this.filterOptions.RequestTypeOptions.ServiceEnabled) {
        this.columnOptions.push({id: 19, name: 'Service'});
      }
      if (this.filterOptions.RequestTypeOptions.SurveyEnabled) {
        this.columnOptions.push({id: 17, name: 'Survey Result'});
        this.columnOptions.push({id: 18, name: 'Survey Status'});
      }
      if (this.filterOptions.RequestTypeOptions.UrgencyEnabled) {
        this.columnOptions.push({id: 23, name: 'Urgency'});
      }
      if (this.filterOptions.RequestTypeOptions.ChangeManagementEnabled) {
        this.columnOptions.push({id: 27, name: 'Change Management State'});
        this.columnOptions.push({id: 36, name: 'Planning Scheduled Start'});
        this.columnOptions.push({id: 37, name: 'Planning Scheduled End'});
        this.columnOptions.push({id: 38, name: 'Requested By Date'});
        this.columnOptions.push({id: 39, name: 'Planning Downtime Start'});
        this.columnOptions.push({id: 40, name: 'Planning Downtime End'});
        this.columnOptions.push({id: 42, name: 'Approval Status'});
      }

      if (this.filterOptions.Columns){
        for(const c of  this.filterOptions.Columns){
            if (typeof c.id === 'string' && c["id"].startsWith("CustomField_")) {
              this.columnOptionsCmdbEnabled.push(c);
              this.columnOptions.push(c)
            }
      }
    }

    })
  }

  selectedColumns() {
    this.totalColumnSelected = []; // Initialize the array

    // Map of column IDs to their names
    const columnsMap = {
        11: this.isCmdbEnabled() ? 'Asset No.' : 'Id',
        28: 'Type',
        10: this.isCmdbEnabled() ? 'Owner' : 'Requester',
        41: 'Requester Email',
        9: this.isCmdbEnabled() ? 'Asset Name' : 'Subject',
        2: 'Status',
        3: 'Priority',
        20: 'Group',
        4: !this.isCmdbEnabled() ? 'Agent' : null,
        14: 'Updated',
        8: 'Created',
        12: 'Responded',
        13: 'Resolved',
        15: 'Response Due',
        16: 'Resolution Due',
        17: 'Survey Result',
        18: 'Survey Status',
        19: 'Service',
        21: 'Customer',
        22: 'Impact',
        23: 'Urgency',
        24: 'Resolution Code',
        25: 'Requester Responded',
        26: 'Last Status Change',
        27: 'Change State',
        29: 'Sla',
        30: 'Asset Type',
        31: 'Serial Number',
        32: 'Imei',
        33: 'Manufacturer',
        34: 'Model',
        35: 'Cost',
        36: 'Planning Scheduled Start',
        37: 'Planning Scheduled End',
        38: 'Requested By Date',
        39: 'Planning Downtime Start',
        40: 'Planning Downtime End',
        42: 'Approval Status'
    };

    // Loop through the map and add enabled columns to totalColumnSelected
    for (const [id, name] of Object.entries(columnsMap)) {
        if (this.data.Columns[id] && name) {
            this.totalColumnSelected.push({ id: parseInt(id), name: name });
        }
    }

    for (const customCol of this.customColumns) {
      // Check if the id already exists in this.totalColumnSelected
      const idExists = this.totalColumnSelected.some(item => item.id === "CustomField_"+customCol['id']);

      if (!idExists) {
          if (customCol['fieldType'] === 2) {
              // Split the name by space, remove the last word, and join back with space
              let modifiedName = customCol['name'].split(' ');
              modifiedName.pop(); // Remove the last word
              modifiedName = modifiedName.join(' ');

              this.totalColumnSelected.push({ id: "CustomField_"+customCol['id'], name: modifiedName });
          } else {
              this.totalColumnSelected.push({ id: "CustomField_"+customCol['id'], name: customCol['name'] });
          }
      }
  }
    // this.totalColumnSelected.push(...this.customColumns)
}


  getPages() {
    if (this.data.ReadOnlyFilter) {
      // There are no pages with read-only filters, its all on one page!
      return Array(0);
    } else {
      let pageCount = Math.ceil(this.data.Count / this.data.Limit);

      const array = new Array(pageCount);

      return array;

    }
  }

  onOptionSelect(event){
    let perPage = 50
    this.isLoading = true;
      this.apiFilter.executeView(this.viewName, this.selectedRequestTypeId, this.OrderById, this.OrderAsc, this.currentPage, perPage, this.defaultTicketOptions).then(response => {
        this.data = response;
        this.recordCount = response.Count
        this.isLoading = false;
        // set the selected dropdown value in storage
        let sort_obj = {defaultTicketOption: this.defaultTicketOptions};
        localStorage.setItem('ticketOption_my_tickets', JSON.stringify(sort_obj));
      })
      // this.getTotalCount();
  }

  editFilter() {
    const dialogParams = {
      create: false,
      requestTypeId: null,
      filterId: this.filterId
    };

    if (this.isCmdbEnabled()) {
      dialogParams["cmdbEnabled"] = 1;
    }

    this.dialogService.createDialog(FilterCreateEditBladeComponent, dialogParams, this.viewReference)
      .then(dialogSaved => {
        this.loadData();
      })
      .catch(dialogCancelled => {
        console.log(dialogCancelled);
      });
  }
  currentPageNo() {
    return this.data.Offset / this.data.Limit + 1;
  }

  lastPageNo() {
    return Math.ceil(this.data.Count / this.data.Limit);
  }

  changePage(pageNo) {
    if (pageNo > this.lastPageNo() || pageNo < 1) {
      return;
    } else {
      this.CurrentPage = pageNo;
      this.loadData(false);
    }
  }


  switchAscDesc() {
    this.OrderAsc = this.OrderAsc === 1 ? 0 : 1;
  }

  changeOrderBy(columnId) {

    if (this.OrderById == columnId) {
      this.switchAscDesc();
    } else {
      this.OrderById = columnId;
      this.OrderAsc = 1;
      this.CurrentPage = 1;
    }

    // save to sort storage
    this.sort_my_tickets.ascending = this.OrderAsc;
    this.sort_my_tickets.orderby =  columnId;
    SdeskHelper.execSortStoreRoutine('sort_my_tickets', columnId, this.OrderAsc);

    this.loadData(false);
  }

  getCustomFieldData(id, customFieldId) {
    const propertyName = `CustomField_${customFieldId}`;
    return this.data.filter(ticket => ticket.id == id)[propertyName];
  }

  loadData(refreshSilently = false, clearSelection = false, fromSearch = false) {
    // the idea for this is, when this method is called from "filter search", it should initialize the current page to "1"
    // because its  a brand new query based off of the search query parameter
    if (fromSearch === true) {
      this.CurrentPage = 1;
    }

    this.customColumns=[]

    // this is for the selected ticket option on the dropdown, if thres any
    this.defaultTicketOptions = SdeskHelper.getSelectedTicketOption();

    if (!refreshSilently) {
      this.isLoading = true;
    }

    if (clearSelection) {
      this.clearSelection();
    }

    if (this.filterId) {
      this.apiFilter.executeFilter(this.filterId, this.OrderById, this.OrderAsc, this.CurrentPage, undefined, this.searchText, this.defaultSearchType).then(response => {
        this.data = response;
        if (this.data && this.data.ColumnsCustomFields) {
          this.customColumns = this.data.ColumnsCustomFields.sort((a, b) => a.id - b.id);
        }
        this.selectedColumns()
        if (this.data && this.data.Results) {
          for(const result of this.data.Results){
            result["customFieldValues"] = this.extractCustomFields(result)
          }
        }
        console.log(this.data.Results)
        this.isLoading = false;
      })
    } else {
      let perPage = 50
      this.apiFilter.executeView(this.viewName, this.selectedRequestTypeId, this.OrderById, this.OrderAsc, this.currentPage, perPage, this.defaultTicketOptions).then(response => {
        this.data = response;
        this.recordCount = response.Count
        this.isLoading = false;
      })
      // this.getTotalCount();
    }
  }

  loadDataOnInit(refreshSilently = false, clearSelection = false) {
    this.customColumns=[]

    // this is for the selected ticket option on the dropdown, if thres any
    this.defaultTicketOptions = SdeskHelper.getSelectedTicketOption();

    if (!refreshSilently) {
      this.isLoading = true;
    }

    if (clearSelection) {
      this.clearSelection();
    }

    // get the stored sort info
    this.sort_my_tickets = JSON.parse(localStorage.getItem('sort_my_tickets'));
    this.OrderAsc = this.sort_my_tickets.ascending;
    this.OrderById = this.sort_my_tickets.orderby;

    if (this.filterId) {
      this.apiFilter.executeFilter(this.filterId, this.OrderById, this.OrderAsc, this.CurrentPage, undefined, this.searchText, this.defaultSearchType).then(response => {
        this.data = response;
        if (this.data && this.data.ColumnsCustomFields) {
          this.customColumns = this.data.ColumnsCustomFields.sort((a, b) => a.id - b.id);
        }
        this.selectedColumns()
        if (this.data && this.data.Results) {
          for(const result of this.data.Results){
            result["customFieldValues"] = this.extractCustomFields(result)
          }
        }
        console.log(this.data.Results)
        this.isLoading = false;
      })
    } else {
      let perPage = 50
      this.apiFilter.executeView(this.viewName, this.selectedRequestTypeId, this.OrderById, this.OrderAsc, this.currentPage, perPage, this.defaultTicketOptions).then(response => {
        this.data = response;
        this.isLoading = false;
      })
      // this.getTotalCount();
    }
  }

  getTotalCount():void
  {
    this.apiFilter.executeView(this.viewName, this.selectedRequestTypeId, this.OrderById, this.OrderAsc, 1, 50, this.defaultTicketOptions).then(response => {
      this.recordCount = response.Count
      this.isLoading = false;
    })
  }
  isLastPage(): boolean {
    return this.currentPage === Math.ceil(this.recordCount / this.showingRowscount);
  }
  onPageChange(event) {
    let page = event.page + 1;
    let perpage = event.rows;
    this.showingRowscount = page * perpage;
    this.fromRowscount = ((page - 1) * perpage) + 1;

    if (!this.filterId) {
      this.apiFilter.executeView(this.viewName, this.selectedRequestTypeId, this.OrderById, this.OrderAsc, page, perpage).then(
        response => {
          this.data = response;
          this.isLoading = false;
        },
        error => {
          console.error('Failed to fetch data', error);
          this.isLoading = false;
        }
      );
    } else {
      // this section is for "filtered"
      this.CurrentPage = event.page + 1;
      if (this.CurrentPage > this.lastPageNo() || this.CurrentPage < 1) {
        return;
      } else {
        this.apiFilter.executeFilter(this.filterId, this.OrderById, this.OrderAsc, this.CurrentPage, undefined, this.searchText, this.defaultSearchType).then(response => {
          this.data = response;
          if (this.data && this.data.ColumnsCustomFields) {
            this.customColumns = this.data.ColumnsCustomFields.sort((a, b) => a.id - b.id);
          }
          this.selectedColumns()
          if (this.data && this.data.Results) {
            for(const result of this.data.Results){
              result["customFieldValues"] = this.extractCustomFields(result)
            }
          }
          console.log(this.data.Results)
          this.isLoading = false;
        })
      }
    }
  }

  formatDate(date) {
    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  downloadCsv() {
    if (!this.filterId) return;

    this.apiFilter.downloadFilter(
        this.filterId,
        this.OrderById,
        this.OrderAsc,
        null,
        null,
        this.defaultSearchType,
        this.searchText
    )
}

  exportExcel() {
    if (!this.filterId) return;

    this.apiFilter.executeFilter(this.filterId, this.OrderById, this.OrderAsc, null, this.data.Count)
        .then(response => {
            import("xlsx").then(xlsx => {
                const fieldsToRemove = ["AssetCost", "AssetImei", "AssetManufacturer", "AssetModel", "AssetName", "AssetNo", "AssetSerialNo", "AssetType", "CmdbEnabled"];
                const columns = response.Columns;
                const DateFormatToChange = ["DateTimeCreated", "DateTimeUpdated", "DateTimeResponded", "DateTimeResolved", "SlaResponseDueDate", "SlaResolutionDueDate", "DateTimeRequesterResponded", "DateTimeLastStatusChange", "NextSlaDueDateTime", "Planning_ScheduledStart", "Planning_ScheduledEnd", "Requested_Date", "Planning_DowntimeStart", "Planning_DowntimeEnd"];

                const dataMapped = response.Results.map(result => {
                    const mappedResult = {};

                    for (const key in result) {
                        if (this.shouldDeleteKey(key, columns)) {
                            delete result[key];
                        } else if (!response.CmdbEnabled && fieldsToRemove.includes(key)) {
                            delete mappedResult[key];
                        } else if (key.startsWith("CustomField_")) {
                            const customFieldLevel = key.split(' ')[1];
                            const customFieldId = key.split(' ')[0].split("_")[1];
                            if (response.hasOwnProperty('ColumnsCustomFields')) {
                                for (const col of response.ColumnsCustomFields) {
                                    const id = col["id"];
                                    var level = col["name"].split(' ').pop();
                                    if (!["level1", "level2", "level3"].includes(level)) {
                                        level = "SingleText";
                                    }
                                    if (String(col["id"]) === customFieldId) {
                                        if (customFieldLevel.toLowerCase() === level.toLowerCase()) {
                                            const customFieldName = col["name"];
                                            mappedResult[customFieldName] = result[key];
                                        }
                                    }
                                }
                            }
                            delete mappedResult[key];
                        } else if (key === "CustomFieldSingletextValues") {
                            if (result[key] !== null && result[key] !== undefined) {
                                // Split the string by comma to get each ID-value pair
                                const pairs = result[key].split(', ');

                                const extractedValues = pairs.map(pair => {
                                    // Split each pair by colon to separate ID and value
                                    const [id, value] = pair.split(': ');
                                    return { id: id.trim(), value: value ? value.trim() :'', level: 'SingleText' };
                                });

                                if (response.hasOwnProperty('ColumnsCustomFields')) {
                                    for (const col of response.ColumnsCustomFields) {
                                        if (col["fieldType"] === 1 || col["fieldType"] === 3) {
                                            const id = String(col["id"]);
                                            const customFieldName = col["name"];
                                            const extractedValue = extractedValues.find(extracted => extracted.id === id);
                                            if (extractedValue) {
                                                mappedResult[customFieldName] = extractedValue.value;
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (response.hasOwnProperty('ColumnsCustomFields')) {
                                    for (const col of response.ColumnsCustomFields) {
                                        if (col["fieldType"] === 1 || col["fieldType"] === 3) {
                                            const customFieldName = col["name"];
                                            mappedResult[customFieldName] = null;
                                        }
                                    }
                                }
                            }
                            delete mappedResult[key];
                        } else {
                            mappedResult[key] = result[key];
                        }

                        // if(DateFormatToChange.includes(key) && result[key] != null){
                        //   mappedResult[key] = this.formatDate(result[key])
                        // }
                    }

                    return mappedResult;
                });
                console.log(dataMapped);
                const worksheet = xlsx.utils.json_to_sheet(dataMapped);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                this.saveAsExcelFile(excelBuffer, response.Name + "_tickets");
            });
        });
}


  shouldDeleteKey(key, columns) {
    switch (key) {
      case 'CmdbEnabled':
      case 'DateTimeLastRead':
      case 'SurveyRating':
      case 'SurveyComment':
      case 'SlaResponseOverdue':
      case 'SlaResolutionOverdue':
        return true;
      case 'RequestTypeName':
        return !columns[28];
      case 'AssetNo':
      case 'LocalRequestId':
        return !columns[11];
      case 'Requester':
        return !columns[10];
      case 'AssetName':
      case 'Subject':
        return !columns[9];
      case 'Status':
        return !columns[2];
      case 'Priority':
        return !columns[3];
      case 'GroupName':
        return !columns[20];
      case 'Agent':
        return !columns[4];
      case 'DateTimeUpdated':
        return !columns[14];
      case 'DateTimeCreated':
        return !columns[8];
      case 'DateTimeResponded':
        return !columns[12];
      case 'DateTimeResolved':
        return !columns[13];
      case 'SlaResponseDueDate':
        return !columns[15];
      case 'SlaResolutionDueDate':
        return !columns[16];
      case 'SurveyResult':
        return !columns[17];
      case 'SurveyStatus':
        return !columns[18];
      case 'Service':
        return !columns[19];
      case 'Customer':
        return !columns[21];
      case 'Impact':
        return !columns[22];
      case 'Urgency':
        return !columns[23];
      case 'ResolutionCode':
        return !columns[24];
      case 'DateTimeRequesterResponded':
        return !columns[25];
      case 'DateTimeLastStatusChange':
        return !columns[26];
      case 'ChangeState':
        return !columns[27];
      case 'NextSlaDueDateTime':
        return !columns[29];
      case 'NextSlaPaused':
      case 'NextSlaOverdue':
        return !columns[29];
      case 'AssetType':
        return !columns[30];
      case 'AssetSerialNo':
        return !columns[31];
      case 'AssetImei':
        return !columns[32];
      case 'AssetManufacturer':
        return !columns[33];
      case 'AssetModel':
        return !columns[34];
      case 'AssetCost':
        return !columns[35];
      case 'Planning_ScheduledStart':
        return !columns[36];
      case 'Planning_ScheduledEnd':
        return !columns[37];
      case 'Requested_Date':
        return !columns[38];
      case 'Planning_DowntimeStart':
        return !columns[39];
      case 'Planning_DowntimeEnd':
        return !columns[40];
      case 'RequesterEmail':
        return !columns[41];
      case 'ApprovalStatus':
        return !columns[42];
      default:
        return false;
    }
  }

  getCustomFieldName(key, columnsCustomFields) {
    const customFieldId = key.split("_")[1];
    const customFieldInfo = columnsCustomFields[customFieldId];
    if (!customFieldInfo) {
      delete columnsCustomFields[key];
      return; // or any default value you prefer
    }
    return customFieldInfo.name;
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    console.log("downloading file")
    saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


  parseReadOnlyDate(dateString: string): Date {
  // Try to parse the date as "DD/MM/YYYY HH:mm"
  let date = moment(dateString, "DD/MM/YYYY HH:mm", true);
  if (!date.isValid()) {
    // If parsing as "DD/MM/YYYY HH:mm" fails, try "MM/DD/YYYY HH:mm"
    date = moment(dateString, "MM/DD/YYYY HH:mm", true);
  }
  if (!date.isValid()) {
    // If parsing as "MM/DD/YYYY HH:mm" fails, try ISO 8601 format
    date = moment(dateString, moment.ISO_8601, true);
  }
  return date.isValid() ? date.toDate() : new Date(); // Return the parsed date or the current date if invalid
}


  parseDate(dateString: string): Date {
    let date: Date;

    // Determine the preferred format
    const preferredFormat = this.data.PreferredDateFormat;

    if (preferredFormat == null || preferredFormat === "d/m/Y H:i") {
      date = this.parseWithFormat(dateString, "dd/MM/yyyy HH:mm");
    } else if (preferredFormat === "m/d/Y H:i") {
      date = this.parseWithFormat(dateString, "MM/dd/yyyy HH:mm");
    } else {
      date = new Date(dateString); // Fallback to native Date parsing
    }

    if (isNaN(date.getTime())) {
      date = new Date(dateString); // Fallback to ISO 8601 parsing
    }

    // Adjust for the timezone offset
    const timezoneOffset = new Date().getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timezoneOffset);

    return this.isValidDate(date) ? date : new Date();
  }

  parseWithFormat(dateString: string, format: string): Date {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hour, minute] = timePart.split(':');

    if (format === "dd/MM/yyyy HH:mm") {
      return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    } else if (format === "MM/dd/yyyy HH:mm") {
      return new Date(`${year}-${day}-${month}T${hour}:${minute}:00`);
    } else {
      return new Date(dateString);
    }
  }

  isValidDate(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }





  extractCustomFields(result: any): any {
    const customFields = [];

    for (const key in result) {
      if (key.startsWith("CustomField_")) {
        const customField = {};
        const customFieldLevel = key.split(' ')[1];
        const customFieldId = key.split(' ')[0].split("_")[1];
        customField["id"] = customFieldId;
        customField["value"] = result[key];
        customField["level"] = customFieldLevel;
        customFields.push(customField);
      } else if (key === "CustomFieldSingletextValues" && result[key] !== null) {
        // Split the string by comma to get each ID-value pair
        const pairs = result[key].split(', ');

        const extractedValues = pairs.map(pair => {
          // Split each pair by colon to separate ID and value
          const [id, value] = pair.split(': ');
          result["CustomField_"+id.trim()] = (value || '').trim();
          return { id: id.trim(), value: (value || '').trim(), level:'SingleText' };
        });

        // Add extracted values to customFields
        extractedValues.forEach(field => customFields.push(field));
      }
    }

    // Step 2: Check and add missing custom fields from this.data.ColumnsCustomFields
    // Step 2: Check and add missing custom fields from this.data.ColumnsCustomFields
    for (const col of this.data.ColumnsCustomFields) {
      const id = String(col.id); // Ensure id remains a string

      // Check if the id is already in customFields
      const exists = customFields.some(field => field.id === id);

      // If not exists, add the item with the specified structure
      if (!exists) {
        const level = col.level || 'SingleText'; // Default level to 'SingleText' if not provided
        customFields.push({ id, value: null, level });
      }
    }

    return customFields;
  }


  isCmdbEnabled() {
    if (this.data && this.data.hasOwnProperty('CmdbEnabled') && this.data.CmdbEnabled === 1) {
      return true;
    }
    return false;
  }
  filterCustomFieldValues(customFieldValues: any[], col): any {
    const id = String(col["id"]); // Ensure id is treated as string
    const level = col["name"] ? col["name"].split(' ').pop() : null;
    const fieldType = col["fieldType"];

    for (const x of customFieldValues) {
      if (x["id"] === id) {
        if (fieldType === 1 || fieldType===3) {
          return x["value"]; // Return value directly if fieldType is 1
        } else if (!level || (x["level"] && x["level"].toLowerCase() === level.toLowerCase())) {
          return x["value"]; // Return value if level matches or is not specified
        }
      }
    }

    return null; // Return null if no matching custom field value is found
  }

  checkboxIsAllSelected() {
    if(this.data)
      {
        return this.selectedTickets.selected.length == (this.data.Results).length;
      }

  }

  checkboxToggleAll() {
    if (this.checkboxIsAllSelected()) {
      this.data.Results.forEach(request => {
        this.selectedTickets.deselect(request.Id);
      })
    } else {
      this.data.Results.forEach(request => {
        this.selectedTickets.select(request.Id);
      })
    }
  }

  openSheduleReports() {
    this.dialogService.createDialog(ScheduleFilterReportsComponent, this.data, this.viewReference).then((dialogSaved) => {
      this.loadData()
    }).catch(dialogCancelled => {
      console.log(dialogCancelled);
    });

  }
}
