// Import Modules / Dependencies
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {HighchartsChartModule} from 'highcharts-angular';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppRoutingModule} from './app-routing.module';
import {MomentModule} from 'ngx-moment';
import {QuillModule} from 'ngx-quill';
import {NgSelectModule} from '@ng-select/ng-select';
import {CommonModule} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ToastrModule} from 'ngx-toastr';



// Import Components
import {AppComponent} from './app.component';
import {BladeRightComponent} from './shared/blade-right/blade-right.component';
import {DashboardComponent} from './agent/pages/dashboard/dashboard.component';
import {AgentComponent} from './agent/agent.component';
import {LoginComponent} from './login/login.component';
import {SelfServiceComponent} from './self-service/self-service.component';
import {ParentComponent} from './test/parent/parent.component';
import {ChildComponent} from './test/child/child.component';
import {ModaltestComponent} from './test/modaltest/modaltest.component';
import {ModalComponent} from './shared/modal/modal.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoadingSpinnerComponent} from './shared/loading-spinner/loading-spinner.component';
import {HighchartPieComponent} from './shared/highchart-pie/highchart-pie.component';
import {HighchartColumnComponent} from './shared/highchart-column/highchart-column.component';
import {LoadingDotsComponent} from './shared/loading-dots/loading-dots.component';
import {DashboardSettingsComponent} from './agent/pages/dashboard/dashboard-settings/dashboard-settings.component';
import {ButtonComponent} from './test/button-component/button-component.component';
import {FilterComponent} from './agent/pages/filter/filter.component';
import {TicketBulkUpdateComponent} from './shared/ticket-bulk-update/ticket-bulk-update.component';
import {TicketBulkUpdateMergeSelectPrimaryComponent} from './shared/ticket-bulk-update/ticket-bulk-update-merge-select-primary/ticket-bulk-update-merge-select-primary.component';
import {ModalConfirmationComponent} from './shared/modal-confirmation/modal-confirmation.component';
import {NewTicketBladeComponent} from './shared/new-ticket-blade/new-ticket-blade.component';
import {DialogComponentComponent} from './templates/dialog-component/dialog-component.component';
import {ParentOpeningDialogComponent} from './templates/parent-opening-dialog/parent-opening-dialog.component';
import {RequesterSearchModalComponent} from './shared/requester-search-modal/requester-search-modal.component';
import {CustomerServiceSearchModalComponent} from './shared/customer-service-search-modal/customer-service-search-modal.component';
import {CustomFieldDropdownComponent} from './shared/custom-field-dropdown/custom-field-dropdown.component';
import {FilterCreateEditBladeComponent} from './shared/filter-create-edit-blade/filter-create-edit-blade.component';
import { ScheduleFilterReportsComponent } from './shared/schedule-filter-reports/schedule-filter-reports.component';
import {MatSelectModule} from "@angular/material/select";
import {RequestComponent} from './agent/pages/request/request.component';
import {RequestChangeManagementComponent} from './agent/pages/request/request-change-management/request-change-management.component';
import {RequestMajorIncidentManagementComponent} from './agent/pages/request/request-major-incident-management/request-major-incident-management.component';
import {RequestThreadComponent} from './agent/pages/request/request-thread/request-thread.component';
import {RequestChangeTypeModalComponent} from './agent/pages/request/request-change-type-modal/request-change-type-modal.component';
import {RequestLinkModalComponent} from './agent/pages/request/request-link-modal/request-link-modal.component';
import {RequestAuditModalComponent} from './agent/pages/request/request-audit-modal/request-audit-modal.component';
import {FriendlyTimeComponent} from './shared/friendly-time/friendly-time.component';
import {RequestOverridePriorityModalComponent} from './agent/pages/request/request-override-priority-modal/request-override-priority-modal.component';
import {RequestSlaComponent} from './agent/pages/request/request-sla/request-sla.component';
import {RequestThreadItemComponent} from './agent/pages/request/request-thread/request-thread-item/request-thread-item.component';
import {RequestThreadActionsComponent} from './agent/pages/request/request-thread-actions/request-thread-actions.component';
import {RequestFollowersModalComponent} from './agent/pages/request/request-followers-modal/request-followers-modal.component';
import {RequestShowLinkModalComponent} from './agent/pages/request/request-show-link-modal/request-show-link-modal.component';
import {AppRequestChangeApprovalAddMembersDialogComponent} from './agent/pages/request/request-change-management/app-request-change-approval-add-members-dialog/app-request-change-approval-add-members-dialog.component';
import {MajorIncidentLinkCustomersModalComponent} from './agent/pages/request/request-major-incident-management/major-incident-link-customers-modal/major-incident-link-customers-modal.component';
import {MajorIncidentLinkServicesModalComponent} from './agent/pages/request/request-major-incident-management/major-incident-link-services-modal/major-incident-link-services-modal.component';
import {MajorIncidentSendMassCommunicationBladeComponent} from './agent/pages/request/request-major-incident-management/major-incident-send-mass-communication-blade/major-incident-send-mass-communication-blade.component';
import {RequestMajorIncidentThreadComponent} from './agent/pages/request/request-major-incident-thread/request-major-incident-thread.component';
import {RequestServiceModalComponent} from './agent/pages/request/request-service-modal/request-service-modal.component';
import {SearchComponent} from './agent/pages/search/search.component';
import {SelfServiceSearchComponent} from './self-service/self-service-search/search.component';
import {ReportingComponent} from './agent/pages/reporting/reporting.component';
import {MatInputModule} from "@angular/material/input";
import {SelfServiceHomeComponent} from './self-service/self-service-home/self-service-home.component';
import {SelfServiceMyTicketsComponent} from './self-service/self-service-my-tickets/self-service-my-tickets.component';
import {SelfServiceTicketViewComponent} from './self-service/self-service-ticket-view/self-service-ticket-view.component';
import {SelfServiceSolutionsArticleComponent} from './self-service/self-service-solutions-article/self-service-solutions-article.component';
import {SelfServiceNewTicketComponent} from './self-service/self-service-new-ticket/self-service-new-ticket.component';
import {AdminComponent} from './agent/pages/admin/admin.component';
import {ServiceDeskSettingsComponent} from './agent/pages/admin/service-desk-settings/service-desk-settings.component';
import {AdminRequestTypesComponent} from './agent/pages/admin/service-desk-settings/admin-request-types/admin-request-types.component';
import {AdminGlobalSettingsComponent} from './agent/pages/admin/service-desk-settings/admin-global-settings/admin-global-settings.component';
import {TableViewComponent} from './templates/table-view/table-view.component';
import {AdminSamlComponentDialog} from "./agent/pages/admin/service-desk-settings/admin-saml/admin-saml-create-edit-dialog/admin-saml-create-edit-dialog.component";
import {AdminSamlComponent} from "./agent/pages/admin/service-desk-settings/admin-saml/admin-saml.component";
import {AdminRequestTypesComponentDialog} from "./agent/pages/admin/service-desk-settings/admin-request-types/admin-request-types-create-edit-dialog/admin-request-types-create-edit-dialog.component";
import {AdminAzureAdComponentDialog} from "./agent/pages/admin/service-desk-settings/admin-azure-ad-sync/admin-azure-ad-sync-create-edit-dialog/admin-azure-ad-sync-create-edit-dialog.component";
import {AdminAzureAdComponent} from "./agent/pages/admin/service-desk-settings/admin-azure-ad-sync/admin-azure-ad-sync.component";
import { AdminUserCustomFieldsComponent  } from './agent/pages/admin/user-groups/admin-user-custom-fields/admin-user-custom-fields.component';
import {AdminUserCustomFieldsComponentDialog } from "./agent/pages/admin/user-groups/admin-user-custom-fields/admin-user-custom-fields-create-edit-dialog/admin-user-custom-fields-create-edit-dialog.component";
import {AdminUsersComponentDialog} from "./agent/pages/admin/user-groups/admin-users/admin-users-create-edit-dialog/admin-users-create-edit-dialog.component";
import {AdminUsersComponent} from "./agent/pages/admin/user-groups/admin-users/admin-users.component";
import {UserGroupsComponent} from './agent/pages/admin/user-groups/user-groups.component';
import {AdminGroupsComponent} from "./agent/pages/admin/user-groups/admin-groups/admin-groups.component";
import {AdminGroupsComponentDialog} from "./agent/pages/admin/user-groups/admin-groups/admin-groups-create-edit-dialog/admin-groups-create-edit-dialog.component";
import {AdminCustomersComponent} from "./agent/pages/admin/customers/admin-customers/admin-customers.component";
import {AdminCustomersComponentDialog} from "./agent/pages/admin/customers/admin-customers/admin-customers-create-edit-dialog/admin-customers-create-edit-dialog.component";
import {AdminCustomersLocationsComponentDialog} from "./agent/pages/admin/customers/locations/admin-customers-locations/admin-customers-locations-create-edit-dialog/admin-customers-locations-create-edit-dialog.component";
import {ServicesVendorsComponent} from './agent/pages/admin/services-vendors/services-vendors.component';
import {AdminServiceComponentDialog} from "./agent/pages/admin/services-vendors/admin-services/admin-services-create-edit-dialog/admin-services-create-edit-dialog.component";
import {AdminServiceComponent} from "./agent/pages/admin/services-vendors/admin-services/admin-services.component";
import {AdminVendorsComponent} from "./agent/pages/admin/services-vendors/admin-vendors/admin-vendors.component";
import {AdminVendorsComponentDialog} from "./agent/pages/admin/services-vendors/admin-vendors/admin-vendors-create-edit-dialog/admin-vendors-create-edit-dialog.component";
import {EmailWebhooksComponent} from './agent/pages/admin/email-webhooks/email-webhooks.component';
import {AdminEmailParseComponentDialog} from "./agent/pages/admin/email-webhooks/admin-email-parse/admin-email-parse-create-edit-dialog/admin-email-parse-create-edit-dialog.component";
import {AdminEmailParseComponent} from "./agent/pages/admin/email-webhooks/admin-email-parse/admin-email-parse.component";
import {AdminWebhookComponentDialog} from "./agent/pages/admin/email-webhooks/admin-webhooks/admin-webhooks-create-edit-dialog/admin-webhooks-create-edit-dialog.component";
import {AdminWebhookComponent} from "./agent/pages/admin/email-webhooks/admin-webhooks/admin-webhooks.component";
import {AdminEmailTemplatesComponent} from "./agent/pages/admin/email-webhooks/admin-email-templates/admin-email-templates.component";
import {AdminEmailTemplatesComponentDialog} from "./agent/pages/admin/email-webhooks/admin-email-templates/admin-email-templates-create-edit-dialog/admin-email-templates-create-edit-dialog.component";
import {TemplateSyntaxComponent} from './shared/template-syntax/template-syntax.component';
import {AdminAllowedSenderDomainsComponentDialog} from "./agent/pages/admin/email-webhooks/admin-allowed-sender-domains/admin-allowed-sender-domains-create-edit-dialog/admin-allowed-sender-domains-create-edit-dialog.component";
import {AdminAllowedSenderDomainsComponent} from "./agent/pages/admin/email-webhooks/admin-allowed-sender-domains/admin-allowed-sender-domains.component";
import {SlaComponent} from './agent/pages/admin/sla/sla.component';
import {AdminSlaTemplatesComponent} from "./agent/pages/admin/sla/admin-sla-templates/admin-sla-templates.component";
import {AdminSlaTemplatesComponentDialog} from "./agent/pages/admin/sla/admin-sla-templates/admin-sla-templates-create-edit-dialog/admin-sla-templates-create-edit-dialog.component";
import {AdminSlaHolidaysComponentDialog} from "./agent/pages/admin/sla/admin-sla-holidays/admin-sla-holidays-create-edit-dialog/admin-sla-holidays-create-edit-dialog.component";
import {AdminSlaHolidaysComponent} from "./agent/pages/admin/sla/admin-sla-holidays/admin-sla-holidays.component";
import {AdminLinkConfigurationComponentDialog} from "./agent/pages/admin/service-desk-settings/admin-link-configuration/admin-link-configuration-create-edit-dialog/admin-link-configuration-create-edit-dialog.component";
import {AdminLinkConfigurationComponent} from "./agent/pages/admin/service-desk-settings/admin-link-configuration/admin-link-configuration.component";
import {AdminCannedResponsesComponentDialog} from "./agent/pages/admin/admin-canned-responses/admin-canned-responses-create-edit-dialog/admin-canned-responses-create-edit-dialog.component";
import {AdminCannedResponsesComponent} from "./agent/pages/admin/admin-canned-responses/admin-canned-responses.component";
import {AdminScheduledRequestsComponent} from "./agent/pages/admin/admin-scheduled-requests/admin-scheduled-requests.component";
import {AdminScheduledRequestsComponentDialog} from "./agent/pages/admin/admin-scheduled-requests/admin-scheduled-requests-create-edit-dialog/admin-scheduled-requests-create-edit-dialog.component";
import {TypeSettingsComponent} from './agent/pages/admin/type-settings/type-settings.component';
import {AdminStatusComponentDialog} from "./agent/pages/admin/type-settings/admin-status/admin-status-create-edit-dialog/admin-status-create-edit-dialog.component";
import {AdminStatusComponent} from "./agent/pages/admin/type-settings/admin-status/admin-status.component";
import {AdminPriorityComponentDialog} from "./agent/pages/admin/type-settings/admin-priority/admin-priority-create-edit-dialog/admin-priority-create-edit-dialog.component";
import {AdminImpactComponent} from "./agent/pages/admin/type-settings/admin-impact/admin-impact.component";
import {AdminUrgencyComponentDialog} from "./agent/pages/admin/type-settings/admin-urgency/admin-urgency-create-edit-dialog/admin-urgency-create-edit-dialog.component";
import {AdminPriorityComponent} from "./agent/pages/admin/type-settings/admin-priority/admin-priority.component";
import {AdminImpactComponentDialog} from "./agent/pages/admin/type-settings/admin-impact/admin-impact-create-edit-dialog/admin-impact-create-edit-dialog.component";
import {AdminUrgencyComponent} from "./agent/pages/admin/type-settings/admin-urgency/admin-urgency.component";
import {AdminCustomFieldsComponent} from "./agent/pages/admin/type-settings/admin-custom-fields/admin-custom-fields.component";
import {AdminCustomFieldsComponentDialog} from "./agent/pages/admin/type-settings/admin-custom-fields/admin-custom-fields-create-edit-dialog/admin-custom-fields-create-edit-dialog.component";
import {AdminResolutionCodesComponentDialog} from "./agent/pages/admin/type-settings/admin-resolution-codes/admin-resolution-codes-create-edit-dialog/admin-resolution-codes-create-edit-dialog.component";
import {AdminResolutionCodesComponent} from "./agent/pages/admin/type-settings/admin-resolution-codes/admin-resolution-codes.component";
import {AdminAutoPriorityComponent} from './agent/pages/admin/type-settings/admin-auto-priority/admin-auto-priority.component';
import {AdminAutomationComponent} from "./agent/pages/admin/admin-automation/admin-automation.component";
import {AdminAutomationComponentDialog} from "./agent/pages/admin/admin-automation/admin-automation-create-edit-dialog/admin-automation-create-edit-dialog.component";
import {TagsInputComponent} from './shared/tags-input/tags-input.component';
import {AdminCustomerPortalsComponent} from "./agent/pages/admin/admin-customer-portal/admin-customer-portal.component";
import {AdminCustomerPortalsComponentDialog} from "./agent/pages/admin/admin-customer-portal/admin-customer-portal-create-edit-dialog/admin-customer-portal-create-edit-dialog.component";
import {AdminCustomerPortalCategoryCreateEditDialogComponent} from './agent/pages/admin/admin-customer-portal/admin-customer-portal-category-create-edit-dialog/admin-customer-portal-category-create-edit-dialog.component';
import {AdminCabComponentDialog} from './agent/pages/admin/type-settings/admin-cab/admin-cab-create-edit-dialog/admin-cab-create-edit-dialog.component';
import {AdminCabComponent} from "./agent/pages/admin/type-settings/admin-cab/admin-cab.component";
import {AdminMajorIncidentTasksComponentDialog} from "./agent/pages/admin/type-settings/admin-major-incident-tasks/admin-major-incident-tasks-create-edit-dialog/admin-major-incident-tasks-create-edit-dialog.component";
import {AdminMajorIncidentTasksComponent} from "./agent/pages/admin/type-settings/admin-major-incident-tasks/admin-major-incident-tasks.component";
import {RaiseABugDialogComponent} from './shared/raise-a-bug-dialog/raise-a-bug-dialog.component';
import {TableModuleComponent} from './shared/table-module/table-module.component';
import {TableModule} from "primeng/table";
import {ButtonModule} from 'primeng/button';
import {UserOnlineIndicatorComponent} from './shared/user-online-indicator/user-online-indicator.component';
import {HighchartTimeSeriesComponent} from './shared/highchart-time-series/highchart-time-series.component';
import {TermsOfServiceComponent} from './agent/pages/terms-of-service/terms-of-service.component';
import {AdminSocDirectFeedsComponent} from "./agent/pages/admin/type-settings/admin-soc-direct-feeds/admin-soc-direct-feeds.component";
import {AdminSocDirectFeedsComponentDialog} from "./agent/pages/admin/type-settings/admin-soc-direct-feeds/admin-soc-direct-feeds-create-edit-dialog/admin-soc-direct-feeds-create-edit-dialog.component";
import {MassCommunicationComponent} from "./agent/pages/mass-communication/mass-communication.component";
import {MassCommunicationComponentDialog} from "./agent/pages/mass-communication/mass-communication-create-edit-dialog/mass-communication-create-edit-dialog.component";
import {MassCommunicationAddSubscribersComponent} from './agent/pages/mass-communication/mass-communication-view-dialog/mass-communication-add-subscribers/mass-communication-add-subscribers.component';
import {MassCommunicationViewDialogComponent} from './agent/pages/mass-communication/mass-communication-view-dialog/mass-communication-view-dialog.component';
import { RootComponent } from './agent/pages/root/root.component';
import { RecordTimeComponent } from './shared/record-time/record-time.component';
import {AssetRegisterComponentDialog} from './agent/pages/assets/asset-register/asset-register-create-edit-dialog/asset-register-create-edit-dialog.component';
import {ReportingFilterComponentDialog} from './agent/pages/reporting/reporting-filter-create-edit-dialog/reporting-filter-create-edit-dialog.component'
import {AssetRegisterComponent} from './agent/pages/assets/asset-register/asset-register.component';
import { TicketPopoutBladeComponent } from './shared/ticket-popout-blade/ticket-popout-blade.component';
import {AdminTaskListsComponentDialog} from './agent/pages/admin/admin-task-lists/admin-task-lists-create-edit-dialog/admin-task-lists-create-edit-dialog.component';
import {AdminTaskListsComponent} from './agent/pages/admin/admin-task-lists/admin-task-lists.component';
import { RequestSelectTaskListComponent } from './agent/pages/request/request-select-task-list/request-select-task-list.component';
import { TasksComponent } from './agent/pages/tasks/tasks.component';
import { TasksDialogComponent } from './agent/pages/tasks/tasks-dialog/tasks-dialog.component';
import { SharedTasksComponentComponent } from './agent/pages/tasks/shared-tasks-component/shared-tasks-component.component';
import { AgentSearchModalComponent } from './shared/agent-search-modal/agent-search-modal.component';
import { AllTasksComponent } from './agent/pages/tasks/all-tasks/all-tasks.component';
import {SdeskMultiSelectComponent} from './shared/sdesk-multi-select/sdesk-multi-select.component';
import {AdminChatConfigComponent} from './agent/pages/admin/admin-chat-config/admin-chat-config.component';
import {AdminChatConfigDialogComponent} from './agent/pages/admin/admin-chat-config/admin-chat-config-dialog/admin-chat-config-dialog.component';
import {ChatComponent} from './agent/pages/chat/chat.component';
import {ChatWindowComponent} from './agent/pages/chat/chat-window/chat-window.component';
import {ApiKeyDialogComponent} from './agent/pages/api-key-dialog/api-key-dialog.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AdminAssetTypesComponent } from './agent/pages/admin/type-settings/admin-asset-types/admin-asset-types.component';
import { AdminAssetTypesComponentDialog } from './agent/pages/admin/type-settings/admin-asset-types/admin-asset-types-create-edit-dialog/admin-asset-types-create-edit-dialog.component';
import { AuditLogComponent } from './agent/pages/admin/audit-log/audit-log.component';
import { PaginatorModule } from 'primeng/paginator';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule,NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';
import { CalendarModule } from 'primeng/calendar';

var toolbarOptions = [
  ['bold', 'italic', 'underline'],        // toggled buttons
  ['clean'],                                      // remove formatting button
  ['link', 'image'],
  // ['blockquote', 'code-block'],


  // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  // [{ 'direction': 'rtl' }],                         // text direction
  //
  // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  //
  [{'color': []}],          // dropdown with defaults from theme
  // [{ 'font': [] }],
  // [{ 'align': [] }],

];

@NgModule({
    declarations: [
        AppComponent,
        BladeRightComponent,
        DashboardComponent,
        AgentComponent,
        LoginComponent,
        SelfServiceComponent,
        ChildComponent,
        ParentComponent,
        ModaltestComponent,
        ModalComponent,
        LoadingSpinnerComponent,
        HighchartPieComponent,
        HighchartColumnComponent,
        LoadingDotsComponent,
        DashboardSettingsComponent,
        ButtonComponent,
        FilterComponent,
        TicketBulkUpdateComponent,
        TicketBulkUpdateMergeSelectPrimaryComponent,
        ModalConfirmationComponent,
        NewTicketBladeComponent,
        DialogComponentComponent,
        ParentOpeningDialogComponent,
        RequesterSearchModalComponent,
        CustomerServiceSearchModalComponent,
        CustomFieldDropdownComponent,
        FilterCreateEditBladeComponent,
        ScheduleFilterReportsComponent,
        RequestComponent,
        RequestChangeManagementComponent,
        RequestMajorIncidentManagementComponent,
        RequestThreadComponent,
        RequestChangeTypeModalComponent,
        RequestLinkModalComponent,
        RequestAuditModalComponent,
        FriendlyTimeComponent,
        RequestOverridePriorityModalComponent,
        RequestSlaComponent,
        RequestThreadItemComponent,
        RequestThreadActionsComponent,
        RequestFollowersModalComponent,
        RequestShowLinkModalComponent,
        AppRequestChangeApprovalAddMembersDialogComponent,
        MajorIncidentLinkCustomersModalComponent,
        MajorIncidentLinkServicesModalComponent,
        MajorIncidentSendMassCommunicationBladeComponent,
        RequestMajorIncidentThreadComponent,
        RequestServiceModalComponent,
        SearchComponent,
        ReportingComponent,
        SelfServiceHomeComponent,
        SelfServiceMyTicketsComponent,
        SelfServiceTicketViewComponent,
        SelfServiceSolutionsArticleComponent,
        SelfServiceNewTicketComponent,
        AdminComponent,
        ServiceDeskSettingsComponent,
        AdminRequestTypesComponent,
        AdminGlobalSettingsComponent,
        TableViewComponent,
        AdminSamlComponentDialog,
        AdminSamlComponent,
        AdminRequestTypesComponentDialog,
        AdminAzureAdComponent,
        AdminAzureAdComponentDialog,
        AdminUserCustomFieldsComponent,
        AdminUserCustomFieldsComponentDialog,
        AdminUsersComponent,
        AdminUsersComponentDialog,
        UserGroupsComponent,
        AdminGroupsComponent,
        AdminGroupsComponentDialog,
        AdminCustomersComponent,
        AdminCustomersComponentDialog,
        AdminCustomersLocationsComponentDialog,
        ServicesVendorsComponent,
        AdminServiceComponent,
        AdminServiceComponentDialog,
        AdminVendorsComponent,
        AdminVendorsComponentDialog,
        EmailWebhooksComponent,
        AdminEmailParseComponentDialog,
        AdminEmailParseComponent,
        AdminWebhookComponentDialog,
        AdminWebhookComponent,
        AdminEmailTemplatesComponent,
        AdminEmailTemplatesComponentDialog,
        TemplateSyntaxComponent,
        AdminAllowedSenderDomainsComponentDialog,
        AdminAllowedSenderDomainsComponent,
        SlaComponent,
        AdminSlaTemplatesComponentDialog,
        AdminSlaTemplatesComponent,
        AdminSlaHolidaysComponentDialog,
        AdminSlaHolidaysComponent,
        AdminLinkConfigurationComponentDialog,
        AdminLinkConfigurationComponent,
        AdminCannedResponsesComponentDialog,
        AdminCannedResponsesComponent,
        AdminScheduledRequestsComponent,
        AdminScheduledRequestsComponentDialog,
        TypeSettingsComponent,
        AdminStatusComponent,
        AdminAssetTypesComponent,
        AdminAssetTypesComponentDialog,
        AdminStatusComponentDialog,
        AdminImpactComponent,
        AdminImpactComponentDialog,
        AdminUrgencyComponent,
        AdminUrgencyComponentDialog,
        AdminPriorityComponent,
        AdminPriorityComponentDialog,
        AdminResolutionCodesComponent,
        AdminResolutionCodesComponentDialog,
        AdminCustomFieldsComponent,
        AdminCustomFieldsComponentDialog,
        AdminAutoPriorityComponent,
        AdminAutomationComponent,
        AdminAutomationComponentDialog,
        TagsInputComponent,
        AdminCustomerPortalsComponent,
        AdminCustomerPortalsComponentDialog,
        AdminCustomerPortalCategoryCreateEditDialogComponent,
        AdminCabComponentDialog,
        AdminCabComponent,
        AdminMajorIncidentTasksComponent,
        AdminMajorIncidentTasksComponentDialog,
        RaiseABugDialogComponent,
        TableModuleComponent,
        UserOnlineIndicatorComponent,
        HighchartTimeSeriesComponent,
        TermsOfServiceComponent,
        AdminSocDirectFeedsComponent,
        AdminSocDirectFeedsComponentDialog,
        MassCommunicationComponent,
        MassCommunicationComponentDialog,
        MassCommunicationAddSubscribersComponent,
        MassCommunicationViewDialogComponent,
        RootComponent,
        RecordTimeComponent,
        AssetRegisterComponentDialog,
        ReportingFilterComponentDialog,
        AssetRegisterComponent,
        TicketPopoutBladeComponent,
        AdminTaskListsComponentDialog,
        AdminTaskListsComponent,
        RequestSelectTaskListComponent,
        TasksComponent,
        TasksDialogComponent,
        SharedTasksComponentComponent,
        AgentSearchModalComponent,
        AllTasksComponent,
        AdminChatConfigComponent,
        AdminChatConfigDialogComponent,
        ChatComponent,
        ChatWindowComponent,
        SelfServiceSearchComponent,
        ApiKeyDialogComponent,
        AuditLogComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HighchartsChartModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MomentModule,
        MatSelectModule,
        QuillModule.forRoot({
            modules: {
                syntax: false,
                toolbar: toolbarOptions
            }
        }),
        NgSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatTooltipModule,
        ToastrModule.forRoot(),
        TableModule,
        ButtonModule,
        SdeskMultiSelectComponent,
        AutoCompleteModule,
        PaginatorModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        CalendarModule 
        // ToastrModule added
        // MatTableModule,
        // MatSortModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'en-GB'},
    ],
    exports: [
        LoadingSpinnerComponent,
        LoadingSpinnerComponent,
        LoadingDotsComponent
    ],
    bootstrap: [AppComponent]
})

export class AppModule {


}
