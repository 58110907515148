<div class="SDeskSidePanel-GreyBackground"></div>

<app-blade-right *ngIf="!isLoading" size="blade-large">
    <div class="p-10 p-b-0">
        <i (click)="this.close()" class="fas fa-times fa-2x float-end divLink"></i>
        <h3>{{'Create'}} Asset Types</h3>
    </div>
    <hr>
    <div class="container p-0">

        <div class="row">
            <div class="col-sm-2 formHeader alignMiddle p-10">
                Name <span class="text-danger">*</span>
            </div>
            <div class="col-sm p-10">
                <input [(ngModel)]="loadedData.Name" type="text" class="form-control" id="Name" placeholder="Name" autocomplete="off">
            </div>
        </div>

        <div class="m-t-20">
            <div (click)="save()" class="btn btn-primary">Save</div>
            <div (click)="close()" class="btn btn-cancel">Cancel</div>
        </div>

    </div>
</app-blade-right>