import { Component, OnInit } from '@angular/core';
import { SearchApiService } from "../../../services/api/search/search-api.service";
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {InstanceParamsService} from "../../../services/instance-params/instance-params.service";


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  public isLoading = false;
  public searchTerm = '';
  public searchResults;
  public sortColumn: string = '';
  public sortOrder: string = 'asc';
  instanceParams: any;


  public searchType = [
    {
      "value":"LocalRequestId",
      "name":"Id",
    },
    { "value":"Subject",
      "name":"Subject",
    },
    {
      "value":"RequesterName",
      "name":"Requester",
    },
    {
      "value":"Body",
      "name":"Body",
    },
    {
      "value":"TaskId",
      "name":"Task Id",
    }
  ];

  public defaultSearchType = "LocalRequestId"

  constructor(
    private searchApiService: SearchApiService,
    private router: Router,
    private location: Location,
    private instanceParamsService: InstanceParamsService
  ) { }

  ngOnInit(): void {
    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });
    const savedSearchTerm = localStorage.getItem('lastSearchTerm');
    const savedSearchResults = JSON.parse(localStorage.getItem('lastSearchResults'));
    const savedSearchType = localStorage.getItem('lastSearchType')

    if (savedSearchTerm) {
      this.searchTerm = savedSearchTerm;
    }
    if(savedSearchType) {
      this.defaultSearchType = savedSearchType;
    }

    if (savedSearchResults) {
      this.searchResults = savedSearchResults;
    }

    this.location.subscribe(() => {
      localStorage.setItem('lastSearchTerm', this.searchTerm);
      localStorage.setItem('lastSearchType', this.defaultSearchType);
      localStorage.setItem('lastSearchResults', JSON.stringify(this.searchResults));
    });

    const clearSearchTermTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds
    setTimeout(() => {
      this.clearSearchTerm();
    }, clearSearchTermTimeout);
  }

  // Strip the 'T' prefix
  stripTPrefix(term: string): string {
    if (term.startsWith('T') || term.startsWith('t')) {
      return term.substring(1);
    }
    return term;
  }

  search() {
    this.isLoading = true;

    let cleanSearchTerm = this.searchTerm;

    if (this.defaultSearchType === 'TaskId') {
      cleanSearchTerm = this.stripTPrefix(this.searchTerm);
    }

    this.searchApiService.newSearch(cleanSearchTerm, this.defaultSearchType).then(response => {
      this.searchResults = response;
      this.isLoading = false;
      this.sortResults();
      console.log('Search Results :: ', this.searchResults)
    });

    localStorage.setItem('lastSearchTerm', this.searchTerm);
    localStorage.setItem('lastSearchType', this.defaultSearchType);
    localStorage.setItem('lastSearchResults', JSON.stringify(this.searchResults));
  }

  sortResults() {
    this.searchResults.sort((a, b) => {
      const modifier = this.sortOrder === 'asc' ? 1 : -1;
      if (a[this.sortColumn] < b[this.sortColumn]) return -1 * modifier;
      if (a[this.sortColumn] > b[this.sortColumn]) return 1 * modifier;
      return 0;
    });
  }

  toggleSortOrder(column: string) {
    if (this.sortColumn === column) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortOrder = 'asc';
    }
    this.sortResults();
  }

  clearSearchTerm() {
    this.searchTerm = '';
    this.searchResults = [];
    localStorage.removeItem('lastSearchTerm');
    localStorage.removeItem('lastSearchResults');
  }

  getStatusText(completed: number): string {
    return completed === 1 ? 'Completed' : 'Pending';
  }
}
