import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {Dialog} from "../../../../../../shared/dialog/dialog";
import {ApiAdminEmailParseService} from "../../../../../../services/api/admin/admin-email-parse-api/admin-email-parse-api.service";
import {OptionsApiService} from "../../../../../../services/api/options/options-api.service";
import {InstanceParamsService} from '../../../../../../services/instance-params/instance-params.service';
import {ToastrService} from "ngx-toastr";
@Component({
  selector: 'app-admin-email-parse-create-edit-dialog',
  templateUrl: './admin-email-parse-create-edit-dialog.component.html'
})

export class AdminEmailParseComponentDialog implements Dialog {

  @Input() data; // {createNewWithRequestTypeId: 112 ,id: null}
  @Output() remove = new EventEmitter<any>();

  isLoading = true;
  isLoadingRequestTypes = true;
  isLoadingOptions = true;
  loadedId = null;

  loadedData;
  requestTypes;
  options;
  instanceParams;
  priorityOptions:number[]=[];
  previousValue;
  existingPriorities;
  highestEmailPriorityRule;
  dataList;

  constructor(
    private apiAdminEmailParseService: ApiAdminEmailParseService,
    private apiAdminOptionsService: OptionsApiService,
    private instanceParamsService: InstanceParamsService,private toastr: ToastrService
  ) {
    this.getRequestTypes();
  }


  ngOnInit(): void {
    // If Id is sent... then its an edit.. else its create new
    if (this.data.id) {
      this.loadedId = this.data.id;
      this.getData();
      this.listAllData();
    } else {
      this.listAllData();
      // Load Default Data
      this.loadedData = {
        DefaultAgentId: null,
        DefaultCustomerId: null,
        DefaultImpactId: null,
        DefaultPriorityId: null,
        DefaultServiceId: null,
        DefaultStatusId: null,
        DefaultSupportGroupId: null,
        EmailPriority:null,
        DefaultTypeId: this.data.createNewWithRequestTypeId,
        DefaultUrgencyId: 0,
        Name: "",
        SDeskEmailAlias: null,
        CustomFields: []
      }

      this.changeRequestType();

      this.isLoading = false;

    }

    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data
    });
    this.priorityOptions = Array.from({ length: this.data.dataLength }, (_, i) => i + 1);

  }

  getRequestTypes() {
    this.isLoadingRequestTypes = true;
    this.apiAdminOptionsService.AllRequestTypes().then(response => {
      this.requestTypes = response;
      this.isLoadingRequestTypes = false;
    })
  }

  changeSupportGroup() {
    this.loadedData.DefaultAgentId = null;
  }

  getCustomFieldOptions(customFieldId) {
    return this.options.CustomFields.find(cf => cf.CustomFieldId == customFieldId).Options;
  }

  changeRequestType() {

    // Reset values because type has changed
    this.loadedData.DefaultAgentId = null;
    this.loadedData.DefaultCustomerId = null;
    this.loadedData.DefaultImpactId = null;
    this.loadedData.DefaultPriorityId = null;
    this.loadedData.DefaultServiceId = null;
    this.loadedData.DefaultStatusId = null;
    this.loadedData.DefaultSupportGroupId = null;
    this.loadedData.DefaultUrgencyId = null;
    this.loadedData.CustomFields = [];

    this.refreshOptions();

  }

  // Call RefreshOptions when Customer/Group changed
  refreshOptions() {
    this.isLoadingOptions = true;
    if (this.loadedData.DefaultTypeId) {
      this.apiAdminOptionsService.DynamicOptions(
        this.loadedData.DefaultTypeId,
        this.loadedData.DefaultSupportGroupId,
        this.loadedData.DefaultCustomerId
      ).then(response => {
        this.options = response;
        this.isLoadingOptions = false;
      })
    }
  }

  getData() {
    this.isLoading = true;
    this.apiAdminEmailParseService.get(this.loadedId).then(response => {
      this.loadedData = response;
      this.previousValue = this.loadedData.EmailPriority;
      this.isLoading = false;

      // Refresh Options after data is loaded
      this.refreshOptions();
    })
  }
  
  listAllData(){
    this.apiAdminEmailParseService.listAll().then(response => {
      this.dataList = response;
      this.filteremailList(this.dataList);
    })
  }

  filteremailList(dataList) {
    this.existingPriorities = dataList.filter(rule => rule.EmailPriority !== 0);
    if (this.existingPriorities.length !== 0) {
      this.highestEmailPriorityRule = this.existingPriorities.reduce((prev, current) => {
        return (prev.EmailPriority < current.EmailPriority) ? prev : current;
      });
    }
  }

  close() {
    this.remove.emit(null);
  }

  checkPriorityExists() {
    let priority = this.loadedData.EmailPriority;
    let loadedId = this.loadedData.Id;
    if (this.existingPriorities.length !== 0) {
      var filteredEmailParseRules = this.existingPriorities.filter(rule => rule.EmailPriority == priority);
    }
    if (this.highestEmailPriorityRule && this.highestEmailPriorityRule !== undefined) {
      if ((priority !== 0) || (priority !== null)) {
        //get data that the emailpriority is not 0//
        const emailPriorities = this.existingPriorities.map(item => item.EmailPriority);
        //getting all less priorities possible for the selected priority if priority = 5 less priorities [1,2,3,4]//
        const selectedPriority = Array.from({ length: priority - 1 }, (_, i) => i + 1);
        //if data has all the less priorities////
        const allPrioritiesExist = selectedPriority.every(priority => emailPriorities.includes(priority));
        //if below prorities not fullfilled pop error///
        if (!allPrioritiesExist && filteredEmailParseRules.length == 0) {
          this.toastr.error(`Please select the highest priority possible`, 'Error');
        }
        else {
          if (this.loadedData.Id == this.highestEmailPriorityRule.Id && (this.highestEmailPriorityRule.EmailPriority < priority) && this.highestEmailPriorityRule.EmailPriority !== null) {
            this.toastr.error(`Highest priority cannot be overridden. Please reset it first`, 'Error');
          }
          else if (filteredEmailParseRules && filteredEmailParseRules.length > 0 && filteredEmailParseRules[0].Id !== loadedId) {
            this.toastr.error(`Selected priority already exists. Please reset it first`, 'Error');
          }
          else if (this.previousValue !== 0 && (this.previousValue < this.loadedData.EmailPriority) && this.highestEmailPriorityRule.EmailPriority !== null) {
            this.toastr.error(`Cannot assign a lower priority than the existing. Please reset it first`, 'Error');
          }
          else {
            this.save();
          }
        }
      }
    }
    else {
      if (priority !== 1) {
        this.toastr.error(`Please select the highest priority possible`, 'Error');
      }
      if (priority == 1) {
        this.save()
      }
    }
  }

  save() {
    if (this.loadedId) {
      this.apiAdminEmailParseService.update(this.loadedId, this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    } else {
      this.apiAdminEmailParseService.create(this.loadedData).then(response => {
        this.remove.emit("Saved");
      })
    }
  }
}
