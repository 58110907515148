<div class="row justify-content-md-center">
  <div class="m-t-10 col-md-8 col-lg-4 col-xl-3 loginFormWrapper">
    <img *ngIf="branding?.LogoUrl" alt="Logo" style="max-height:40px; max-width:200px; margin-bottom: 20px"
         class="ng-scope" [src]="branding?.LogoUrl">

    <p *ngIf="showLoginFailedMessage" class="text-danger">
      Incorrect login details provided.
    </p>

    <p *ngIf="showPasswordResetSuccessful" class="text-success">
      Your password has been reset. Please check your email for further instructions.
    </p>

    <form>

      <div class="mb-3">
        <label for="emailAddress" class="form-label">Email address</label>
        <input [disabled]="this.loginMethod" [(ngModel)]="this.emailAddress" type="email" class="form-control" id="emailAddress" name="emailAddress">
      </div>

    <div *ngIf="screen == 'loginScreen'">

      <div *ngIf="loginMethod == 'Username-Password' || screen == 'passwordResetScreen'" class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input [(ngModel)]="this.password" name="password" type="password" class="form-control" id="password">
      </div>

      <div class="d-grid gap-2">
        <button (click)="getLoginMethod()" *ngIf="!loginMethod" (keyup.enter)="getLoginMethod()" class="btn btn-primary">Login</button>
        <button (click)="login()" (keyup.enter)="login()" *ngIf="loginMethod == 'Username-Password'" class="btn btn-primary">Login</button>
        <button (click)="login()" (keyup.enter)="login()"  *ngIf="loginMethod == 'SSO'" class="btn btn-primary">Login with Office 365</button>
      </div>

      <hr>
      <p (click)="screen = 'passwordResetScreen'" class="divLink"><i class="fa fa-lock m-r-5"></i> Forgot your password?</p>
      <p (click)="clearLoginMethod()" class="divLink" style="color: #337ab7">Sign in as different user</p>

    </div>

      <div *ngIf="screen == 'passwordResetScreen'">
        <div class="d-grid gap-2">
          <button (click)="resetPassword()" (keyup.enter)="resetPassword()" class="btn btn-primary">Reset Password</button>
        </div>

        <hr>
        <p (click)="screen = 'loginScreen'" class="divLink"><i class="fa fa-lock m-r-5"></i> Remembered your password? Click here to Login</p>

      </div>



    </form>
  </div>
</div>


