import {ChangeDetectorRef, Component, OnInit, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication/authentication.service";
import {DashboardApiService} from "../../../services/api/agent/dashboard/dashboard-api-service";
import * as Highcharts from 'highcharts';
import {ActivatedRoute} from "@angular/router";
import {SelectionModel} from "@angular/cdk/collections";
import {UserPermissionsService} from "../../../services/user-permissions/user-permissions.service";
import {timer} from 'rxjs';
import {DialogService} from '../../../services/dialog-service/dialog.service';
import {FilterCreateEditBladeComponent} from '../../../shared/filter-create-edit-blade/filter-create-edit-blade.component';
import {TicketPopoutBladeComponent} from '../../../shared/ticket-popout-blade/ticket-popout-blade.component';
import {InstanceParamsService} from "../../../services/instance-params/instance-params.service";
import { SdeskHelper } from 'src/app/shared/helper/SdeskHelper';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  public statsLoading = true;
  public statsData;
  public statsTrendData = {"Trend":[]};
  public statsTrendLoading = true;
  public selectedTab = '';

  public viewLoading = true;
  public viewData;

  public showDashboardSettings = false;

  public dashboardSettings;
  instanceParams: any;


  orderTableBy = 'NextSlaDueDateTime';
  orderTableByAsc = true;

  loadTicketsInBlade = false;

  refreshTimer = timer(60000, 60000);
  timerSubscription;

  public selectedTickets = new SelectionModel(
    true,   // multiple selection or not
    [] // initial selected values
  );

  public mostRecentUnassignedTicket = null;
  public sort_dashboard = {orderby: 0, ascending: 0};
  public has_sort_dashboard = false;

  constructor(
    private auth: AuthenticationService,
    private dashboardApi: DashboardApiService,
    private route: ActivatedRoute,              private dialogService: DialogService,
    private viewReference: ViewContainerRef,
    private instanceParamsService: InstanceParamsService,
  ) {
    auth.checkLoggedIn();

    this.selectedTickets.changed.subscribe(s => console.log(s));

    // Subscribe to the timer
    this.timerSubscription = this.refreshTimer.subscribe(val => this.refresh());
  }

  ngOnDestroy() {
    // Unsubscribe from the timer
    this.timerSubscription.unsubscribe();
  }

  clearSelection() {
    this.selectedTickets.clear();
  }

  closeSettings() {
    this.showDashboardSettings = false;
    this.loadStats();
  }

  openTicketBlade(ticketId) {
    console.log("calling createFilter");
    this.dialogService.createDialog(TicketPopoutBladeComponent, ticketId, this.viewReference)
      .then(dialogSaved => {
        console.log("dialogSaved:" + dialogSaved);
      }, dialogCancelled => {
        console.log("dialogCancelled:" + dialogCancelled);
      })
  }

  changeOrder(column) {
    if (this.orderTableBy == column) {
      this.orderTableByAsc = !this.orderTableByAsc;
      this.sortData();
    } else {
      this.orderTableBy = column;
      this.orderTableByAsc = true;
      this.sortData();
    }
    SdeskHelper.execSortStoreRoutine('sort_dashboard', SdeskHelper.getColId(column), Number(this.orderTableByAsc));
  }

  sortData() {
    if (this.orderTableBy === 'LocalRequestId') {
      // this new column has integer values, so we use sorting for integer
      SdeskHelper.sortInt(this.viewData, this.orderTableBy);
    } else {
      SdeskHelper.sortNonInt(this.viewData, this.orderTableBy);
    }
    if (!this.orderTableByAsc) {
      this.viewData = this.viewData.reverse();
    }
  }

  reverseSortOrder() {
  }

  getStatsLocalCache(){

    console.log("Getting stats from cache");
    let stats = JSON.parse(localStorage.getItem('dashboard_stats'));

    if(stats){
      this.statsData = stats;
      this.dashboardSettings = stats.DashboardSettings;

      this.statsLoading = false;

      console.log("Finished loading stats from cache");

      this.loadStats(true);

    } else {
      this.loadStats(false);

    }


  }

  setStatsLocalCache(stats){
    localStorage.setItem('dashboard_stats', JSON.stringify(stats));
  }

  loadStats(silent = true) {
    console.log("Calling stats API silent:"+silent)
    if(!silent){
      this.statsLoading = true;
    }
    this.dashboardApi.getDashboardStats().then(results => {
      this.statsData = results;
      this.statsLoading = false;

      this.setStatsLocalCache(results);

      this.dashboardSettings = results.DashboardSettings;
      this.checkNewTicketNotificationSound();

    }).catch(error => {
      console.log(error);
    })
  }

  refreshTrend(){
    let lastSync = localStorage.getItem('dashboard_trend_last_sync');

    // if lastSync is null or more than 24 hours ago
    if (!lastSync || (new Date().getTime() - new Date(lastSync).getTime()) > 24 * 60 * 60 * 1000) {
      this.loadTrend();
    } else {
      this.statsTrendData = JSON.parse(localStorage.getItem('dashboard_trend'));
      this.statsTrendLoading = false;

    }
  }

  loadTrend() {
    this.statsTrendLoading = true;
    this.dashboardApi.getDashboardTrend().then(results => {
      this.statsTrendData = results;

      this.statsTrendLoading = false;

      this.setTrendLocalCache(results);

    }).catch(error => {
      console.log(error);
    })
  }

  setTrendLocalCache(stats){
    localStorage.setItem('dashboard_trend', JSON.stringify(stats));
    // Set the current date/time as the last sync time
    localStorage.setItem('dashboard_trend_last_sync', new Date().toISOString());
  }

  checkNewTicketNotificationSound() {
    if (this.dashboardSettings.NewTicket_Sound) {

      if (!this.mostRecentUnassignedTicket) {
        this.mostRecentUnassignedTicket = this.statsData.UnassignedRequests.MaxDateTimeCreated
      } else {
        if (this.statsData.UnassignedRequests.MaxDateTimeCreated > this.mostRecentUnassignedTicket) {
          this.mostRecentUnassignedTicket = this.statsData.UnassignedRequests.MaxDateTimeCreated;
          this.playAudio();
        }
      }


    }
  }

  loadView(viewName) {
    console.log("Loading View:" + viewName)

    this.viewLoading = true;
    this.dashboardApi.getDashboardView(viewName).then(results => {
      this.viewData = results;
      // Sort data after refresh
      this.sortData();
      this.clearSelection();
      this.viewLoading = false;
    }).catch(error => {
      console.log(error);
    })
  }

  refresh(clearSelection = false) {

    if (clearSelection) {
      this.clearSelection();
    }

    this.loadStats();
    this.refreshTrend();
    if (this.selectedTab != 'stats') {
      this.loadView(this.selectedTab);
    }
  }

  ngOnInit() {
    this.initSortStorage();
    this.instanceParamsService.refreshInstanceParams();
    this.instanceParamsService.instanceParamsSubscription.subscribe(data => {
      this.instanceParams = data;
    });

    this.route.paramMap.subscribe(params => {
      this.selectedTab = params.get("tab");

      this.getStatsLocalCache();
      this.refreshTrend();

      if (this.selectedTab != 'stats') {
        this.loadView(this.selectedTab);
      }
    })
  }

  private initSortStorage() {
    this.sort_dashboard = JSON.parse(localStorage.getItem('sort_dashboard'));    
    // if not existing on local storage, create it
    if (this.sort_dashboard === null) {
      SdeskHelper.initSortStorage('sort_dashboard');
      this.orderTableByAsc = true;
    } else {
      // if existing on local storage, use it
      this.orderTableByAsc = Boolean(this.sort_dashboard.ascending);
      this.orderTableBy =  SdeskHelper.getColName(this.sort_dashboard.orderby, SdeskHelper.DASHBOARD);
   }
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "/assets/notification.wav";
    audio.load();
    audio.play();
  }

  checkboxIsAllSelected() {
    return this.selectedTickets.selected.length == this.viewData.length;
  }

  checkboxToggleAll() {
    if (this.checkboxIsAllSelected()) {
      this.viewData.forEach(request => {
        this.selectedTickets.deselect(request.Id);
      })
    } else {
      this.viewData.forEach(request => {
        this.selectedTickets.select(request.Id);
      })
    }
  }

}
