<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading" class="table-responsive p-l-20 p-r-20">

    <table class="table align-middle tableStyle">
        <thead>
            <tr>
                <th class="">Asset Types
                    <div (click)="create()" class="btn btn-primary float-end">Create</div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of this.data ">
                <td><span class="divLink">{{row.Name}}</span>
                    <i *ngIf="row.InstanceId !== null" class="far fa-trash-alt float-end m-r-20 divLink" (click)="delete(row.Id)"></i>
                    <i *ngIf="row.InstanceId !== null" class="fas fa-cog float-end m-r-20 divLink" (click)="edit(row.Id)"></i>
                </td>
            </tr>
        </tbody>
    </table>
</div>