<div class="m-t-10 m-b-10">
  <h4 class="p-10 p-b-0"><strong>Search</strong></h4>

   <!-- <div class="p-10 p-t-5">  -->
  <div class="row">
      <div class="col-md-2" style="display: flex; align-items: stretch;">
        <select [(ngModel)]="defaultSearchType" (ngModelChange)="clearSearchTerm()" class="form-control large-search-box" data-style="btn-white" style="height: 100%;">
          <option *ngFor="let option of this.searchType" [label]="option.name" [value]="option.value" selected="selected"></option>
        </select>
      </div>
      <div class="col-md-10">
    <input [(ngModel)]="searchTerm" (keyup.enter)="search()" type="text" class="form-control large-search-box" autocomplete="off" placeholder="Search..." id="search">
  </div>
  <!-- </div> -->


  <div class="row">
    <div class="sectionBox p-0 col">

      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>


      <div *ngIf="!isLoading" class="table-responsive">
        <table class="table align-middle tableStyle" *ngIf="defaultSearchType !== 'TaskId'">
          <thead>
          <tr>
            <th class="table-header" (click)="toggleSortOrder('LocalRequestId')">Id
              <span *ngIf="sortColumn === 'LocalRequestId' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('RequestTypeName')">Type
              <span *ngIf="sortColumn === 'RequestTypeName' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('RequesterName')">Requester
              <span *ngIf="sortColumn === 'RequesterName' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('Subject')">Subject
              <span *ngIf="sortColumn === 'Subject' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('Status')">Status
              <span *ngIf="sortColumn === 'Status' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('Priority')">Priority
              <span *ngIf="sortColumn === 'Priority' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('Agent')">Agent
              <span *ngIf="sortColumn === 'Agent' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('DateTimeUpdated')">Updated
              <span *ngIf="sortColumn === 'DateTimeUpdated' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
            <th class="table-header" (click)="toggleSortOrder('DateTimeCreated')">Created
              <span *ngIf="sortColumn === 'DateTimeCreated' && sortOrder" [ngClass]="sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down'"></span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let request of this.searchResults"  class="divLink">
            <td [routerLink]="['/agent/request/', request.Id]">{{request.LocalRequestId}}</td>
            <td [routerLink]="['/agent/request/', request.Id]">{{request.RequestTypeName}}</td>
            <td [routerLink]="['/agent/request/', request.Id]">{{request.RequesterName}}</td>
            <td [routerLink]="['/agent/request/', request.Id]" [innerHTML]="request.Subject"></td>
            <td [routerLink]="['/agent/request/', request.Id]">{{request.Status}}</td>
            <td [routerLink]="['/agent/request/', request.Id]">{{request.Priority}}</td>
            <td [routerLink]="['/agent/request/', request.Id]">{{request.Agent}}</td>
            <td [routerLink]="['/agent/request/', request.Id]"><app-friendly-time [utcTimeData]="request.DateTimeUpdated" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time></td>
            <td [routerLink]="['/agent/request/', request.Id]"><app-friendly-time [utcTimeData]="request.DateTimeCreated" [timeFormat] = "instanceParams.preferredDateFormat"></app-friendly-time></td>
          </tr>
          </tbody>
        </table>

        <table class="table align-middle tableStyle" *ngIf="defaultSearchType === 'TaskId'">
          <thead>
            <tr>
              <th class="table-header" (click)="toggleSortOrder('Id')">Task ID</th>
              <th class="table-header" (click)="toggleSortOrder('TaskName')">Task Name</th>
              <th class="table-header" (click)="toggleSortOrder('Instructions')">Instructions</th>
              <th class="table-header" (click)="toggleSortOrder('GroupName')">Group Name</th>
              <th class="table-header" (click)="toggleSortOrder('AssignedTo')">Assigned To</th>
              <th class="table-header" (click)="toggleSortOrder('Completed')">Status</th>
              <th class="table-header" (click)="toggleSortOrder('RequestId')">Ticket Number</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let task of this.searchResults" class="divLink">
              <td [routerLink]="['/agent/request/', task.RequestId]">{{'T' + task.Id}}</td>
              <td [routerLink]="['/agent/request/', task.RequestId]">{{task.TaskName}}</td>
              <td [routerLink]="['/agent/request/', task.RequestId]" [innerHTML]="task.Instructions"> </td>
              <td [routerLink]="['/agent/request/', task.RequestId]">{{task.GroupName}}</td>
              <td [routerLink]="['/agent/request/', task.RequestId]">{{task.AgentName}}</td>
              <td [routerLink]="['/agent/request/', task.RequestId]">{{getStatusText(task.Completed)}}</td>
              <td [routerLink]="['/agent/request/', task.RequestId]">{{task.LocalId}}</td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>
</div>
